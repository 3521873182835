<template>
	<div class="main-header">
		<div class="header-page-container" id="header-page">
			<div class="top-computer">
				<div class="header-container">
					<div class="header-page-top-img">
						<router-link to="/"
							><img
								class="img-responsives"
								src="../assets/imgs/header/logo_main.png"
						/></router-link>
					</div>
					<div style="display: flex">
						<nav class="navbar navbar-expand-lg navbar-light">
							<button
								class="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
								@click="toggleClick"
							>
								<span class="navbar-toggler-icon">
									<img style="width: 30px; height: 20px" :src="lineImg2" />
								</span>
							</button>
							<div class="header-page-top-nav">
								<div
									class="collapse navbar-collapse"
									id="navbarSupportedContent"
								>
									<ul class="navbar-nav mr-auto">
										<li
											:class="{ 'blank-active': routeName === 'Home' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' ||
													routeName === 'Details' ||
													routeName === 'List'
														? 'nav-contact'
														: routeName === 'Share' ||
														  routeName === 'Home' ||
														  routeName === 'Service'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/"
												exact
											>
												{{ $t('localization.homeFont') }}
											</router-link>
										</li>
										<li
											:class="{ active: routeName === 'About' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' ||
													routeName === 'Details' ||
													routeName === 'List'
														? 'nav-contact'
														: routeName === 'Share' ||
														  routeName === 'Home' ||
														  routeName === 'Service'
														? 'nav-blank'
														: 'nav-white'
												"
												:to="{
													path: '/about',
													query: { country: _getCountryValue(curCountry) },
												}"
											>
												{{ $t('localization.aboutFont') }}</router-link
											>
										</li>
										<li
											:class="{ 'blank-active': routeName === 'Service' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' ||
													routeName === 'Details' ||
													routeName === 'List'
														? 'nav-contact'
														: routeName === 'Share' ||
														  routeName === 'Home' ||
														  routeName === 'Service'
														? 'nav-blank'
														: 'nav-white'
												"
												:to="{
													path: '/service',
													query: { country: _getCountryValue(curCountry) },
												}"
											>
												{{ $t('localization.treatmentFont') }}</router-link
											>
										</li>
										<li
											:class="{ active: routeName === 'News' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' ||
													routeName === 'Details' ||
													routeName === 'List'
														? 'nav-contact'
														: routeName === 'Share' ||
														  routeName === 'Home' ||
														  routeName === 'Service'
														? 'nav-blank'
														: 'nav-white'
												"
												:to="{
													path: '/news',
													query: { country: _getCountryValue(curCountry) },
												}"
											>
												{{ $t('localization.newsFont') }}</router-link
											>
										</li>
										<li
											:class="{ 'contact-active': routeName === 'Contact' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' ||
													routeName === 'Details' ||
													routeName === 'List'
														? 'nav-contact'
														: routeName === 'Share' ||
														  routeName === 'Home' ||
														  routeName === 'Service'
														? 'nav-blank'
														: 'nav-white'
												"
												:to="{
													path: '/contact',
													query: { country: _getCountryValue(curCountry) },
												}"
											>
												{{ $t('localization.contactFont') }}</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div class="nav-margin language-style" @click="chooseLangage">
							<img
								v-if="
									(routeName === 'Service' && curCountry == '其他地区') ||
										(routeName === 'Share' && curCountry == '其他地区') ||
										(routeName === 'Home' && curCountry == '其他地区')
								"
								class="lang-font-icon"
								src="../assets/imgs/header/lang_black.png"
							/>
							<img
								v-if="
									(routeName == 'About' && curCountry == '其他地区') ||
										(routeName == 'News' && curCountry == '其他地区')
								"
								class="lang-font-icon"
								src="../assets/imgs/header/lang_white.png"
							/>
							<img
								v-if="
									(routeName == 'Contact' && curCountry == '其他地区') ||
										(routeName == 'List' && curCountry == '其他地区') ||
										(routeName === 'Details' && curCountry == '其他地区')
								"
								class="lang-font-icon"
								src="../assets/imgs/header/lang_purple.png"
							/>

							<img
								class="lang-font-icon"
								style="width: 22px; height: 15px;margin-right: 4px;"
								v-if="curCountry == '英国'"
								src="../assets/imgs/home/en.png"
							/>

							<img
								class="lang-font-icon"
								style="width: 22px; height: 15px;margin-right: 4px;"
								v-if="curCountry == '柬埔寨'"
								src="../assets/imgs/home/jian.png"
							/>

							<span
								v-if="
									routeName === 'Service' ||
										routeName === 'Share' ||
										routeName === 'Home'
								"
								class="lang-font lang-black"
								>{{ langageValue(selectLang) }}</span
							>
							<span
								v-if="routeName == 'About' || routeName == 'News'"
								class="lang-font lang-white"
								>{{ langageValue(selectLang) }}</span
							>
							<span
								v-if="
									routeName == 'Contact' ||
										routeName == 'List' ||
										routeName === 'Details'
								"
								class="lang-font lang-purple"
								>{{ langageValue(selectLang) }}</span
							>
						</div>
					</div>
				</div>
			</div>
			<div class="top-phone">
				<div class="header-container">
					<div style="width: 25px; display: flex">
						<nav class="navbar navbar-expand-lg navbar-light">
							<button
								class="navbar-toggler"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
								@click="toggleClick"
							>
								<span class="navbar-toggler-icon">
									<img style="width: 20px; height: 16px" :src="lineImg2" />
								</span>
							</button>
							<div class="header-page-top-nav">
								<div
									class="collapse navbar-collapse"
									id="navbarSupportedContent"
								>
									<ul class="navbar-nav mr-auto">
										<li
											:class="{ 'blank-active': routeName === 'Home' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' || routeName === 'Details'
														? 'nav-contact'
														: routeName === 'Service' ||
														  routeName === 'Share' ||
														  routeName === 'Home'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/"
												exact
											>
												{{ $t('localization.homeFont') }}
											</router-link>
										</li>
										<li
											:class="{ active: routeName === 'About' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' || routeName === 'Details'
														? 'nav-contact'
														: routeName === 'Service' ||
														  routeName === 'Share' ||
														  routeName === 'Home'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/about"
											>
												{{ $t('localization.aboutFont') }}</router-link
											>
										</li>
										<li
											:class="{ 'blank-active': routeName === 'Service' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' || routeName === 'Details'
														? 'nav-contact'
														: routeName === 'Service' ||
														  routeName === 'Share' ||
														  routeName === 'Home'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/service"
											>
												{{ $t('localization.treatmentFont') }}</router-link
											>
										</li>
										<li
											:class="{ active: routeName === 'News' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' || routeName === 'Details'
														? 'nav-contact'
														: routeName === 'Service' ||
														  routeName === 'Share' ||
														  routeName === 'Home'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/news"
											>
												{{ $t('localization.newsFont') }}</router-link
											>
										</li>
										<li
											:class="{ 'contact-active': routeName === 'Contact' }"
											class="nav-item nav-margin min-liHome"
										>
											<router-link
												:class="
													routeName === 'Contact' || routeName === 'Details'
														? 'nav-contact'
														: routeName === 'Service' ||
														  routeName === 'Share' ||
														  routeName === 'Home'
														? 'nav-blank'
														: 'nav-white'
												"
												to="/contact"
											>
												{{ $t('localization.contactFont') }}</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</nav>
						<div class="nav-margin language-style flex-center">
							<el-dropdown trigger="click" @command="switchLanguage">
								<div class="lang-icon">
									<span class="el-dropdown-link">
										<img
											:src="
												selectLang == 'zh-CN'
													? country1
													: selectLang == 'en-US'
													? country2
													: selectLang == 'zh-TW'
													? country3
													: country4
											"
											style="width: 20px; height: 14px"
										/>
									</span>
								</div>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item
										v-for="item in language"
										:key="item.value"
										:command="item.value"
									>
										<a class="dropdown_a">
											<img
												:src="item.img"
												style="
                          width: 17px;
                          height: 12px;
                          margin-top: -4px;
                          margin-right: 3px;
                        "
											/>
											<span :class="{ selected: selectLang == item.value }">{{
												item.label
											}}</span>
										</a>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<div style="padding-top: 14px;" class="logo-icon">
						<img
							style="width: 120px; height:29px;"
							src="../assets/imgs/home/logo_icon1.png"
							alt=""
						/>
					</div>
					<div class="phone-lang" @click="chooseMobileLangage">
						<img
							class="lang-font-icon"
							style="width: 22px; height: 15px;"
							v-if="curCountry == '英国'"
							src="../assets/imgs/home/en.png"
						/>
						<img
							class="lang-font-icon"
							style="width: 22px; height: 15px;"
							v-if="curCountry == '柬埔寨'"
							src="../assets/imgs/home/jian.png"
						/>
						<img
							class="lang-font-icon"
							style="width: 24px; height: 24px;"
							v-if="curCountry == '其他地区'"
							src="../assets/imgs/header/mobile_lang_icon.png"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="phone-header-container">
			<div class="phone-header">
				<div class="phone-main">
					<ul>
						<li class="phone-main-every" @click="goTop">
							<router-link
								class="oldType"
								:class="{ phoneClick: routeName === 'Home' }"
								to="/"
								exact
							>
								<img
									:src="routeName === 'Home' ? indexImg : indexImg02"
									class="every-img"
								/>
								<span>{{ $t('localization.homeFont') }}</span>
							</router-link>
						</li>
						<li class="phone-main-every" @click="goTop">
							<router-link
								class="oldType"
								:class="{ phoneClick: routeName === 'About' }"
								:to="{
									path: '/about',
									query: { country: _getCountryValue(curCountry) },
								}"
							>
								<img
									:src="routeName === 'About' ? aboutImg : aboutImg02"
									class="every-img"
								/>
								<span>{{ $t('localization.aboutFont') }}</span>
							</router-link>
						</li>
						<li class="phone-main-every" @click="goTop">
							<router-link
								class="oldType"
								:class="{ phoneClick: routeName === 'Service' }"
								:to="{
									path: '/service',
									query: { country: _getCountryValue(curCountry) },
								}"
							>
								<img
									:src="routeName === 'Service' ? serviceImg : serviceImg02"
									class="every-img"
								/>
								<span>{{ $t('localization.treatmentFont') }}</span>
							</router-link>
						</li>
						<li class="phone-main-every" @click="goTop">
							<router-link
								class="oldType"
								:class="{ phoneClick: routeName === 'News' }"
								:to="{
									path: '/news',
									query: { country: _getCountryValue(curCountry) },
								}"
							>
								<img
									:src="routeName === 'News' ? newsImg : newsImg02"
									class="every-img"
								/>
								<span>{{ $t('localization.newsFont') }}</span>
							</router-link>
						</li>
						<li class="phone-main-every" @click="goTop">
							<router-link
								class="oldType"
								:class="{ phoneClick: routeName === 'Contact' }"
								:to="{
									path: '/contact',
									query: { country: _getCountryValue(curCountry) },
								}"
							>
								<img
									:src="routeName === 'Contact' ? contactImg : contactImg02"
									class="every-img"
								/>
								<span>{{ $t('localization.contactFont') }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div @click="closeClick" class="hidden-div"></div>
		</div>

		<!-- mobile端语言弹窗 -->
		<el-drawer
			:visible.sync="mobileDrawer"
			:direction="'ttb'"
			:modal="false"
			size="100%;"
		>
			<div class="langage-mobile-box">
				<!-- 选择您所在的地区和语言 -->
				<div class="title">{{ $t('localization.selectLangageFont') }}</div>
				<div class="line"></div>
				<div class="list">
					<div class="column">
						<div class="col-tit">
							<!-- {{ $t('localization.selectLangageFont2') }} -->
							United Kingdom
						</div>
						<div class="col-sub">
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '英国' && selectLang == 'en-US',
								}"
								@click="switchLanguage('en-US', '英国')"
								>English
							</span>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '英国' && selectLang == 'zh-CN',
								}"
								@click="switchLanguage('zh-CN', '英国')"
								>简体中文</span
							>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '英国' && selectLang == 'zh-TW',
								}"
								@click="switchLanguage('zh-TW', '英国')"
								>繁體中文</span
							>
						</div>
					</div>
					<div class="column">
						<div class="col-tit">
							<!-- {{ $t('localization.selectLangageFont3') }} -->
							Cambodia
						</div>
						<div class="col-sub">
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '柬埔寨' && selectLang == 'en-KH',
								}"
								@click="switchLanguage('en-KH', '柬埔寨')"
								>កម្ពុជា។</span
							>
							<span
								class="col-sub-tit"
								@click="switchLanguage('en-US', '柬埔寨')"
								:class="{
									active: curCountry == '柬埔寨' && selectLang == 'en-US',
								}"
								>English</span
							>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '柬埔寨' && selectLang == 'zh-CN',
								}"
								@click="switchLanguage('zh-CN', '柬埔寨')"
								>简体中文</span
							>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '柬埔寨' && selectLang == 'zh-TW',
								}"
								@click="switchLanguage('zh-TW', '柬埔寨')"
								>繁體中文</span
							>
						</div>
					</div>
					<div class="column">
						<div class="col-tit">
							<!-- {{ $t('localization.selectLangageFont4') }} -->
							INTERNATIONAL
						</div>
						<div class="col-sub">
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '其他地区' && selectLang == 'en-US',
								}"
								@click="switchLanguage('en-US', '其他地区')"
								>English</span
							>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '其他地区' && selectLang == 'zh-CN',
								}"
								@click="switchLanguage('zh-CN', '其他地区')"
								>简体中文</span
							>
							<span
								class="col-sub-tit"
								:class="{
									active: curCountry == '其他地区' && selectLang == 'zh-TW',
								}"
								@click="switchLanguage('zh-TW', '其他地区')"
								>繁體中文</span
							>
						</div>
					</div>
				</div>
			</div>
		</el-drawer>

		<!-- pc端语言弹窗 -->
		<el-drawer
			:visible.sync="drawer"
			:direction="'ttb'"
			:modal="false"
			size="80%"
		>
			<div class="langage-pc-box">
				<!-- 选择您所在的地区和语言 -->
				<div class="title">{{ $t('localization.selectLangageFont') }}</div>
				<div class="line"></div>
				<!-- <div class="font-wrap">
					<span class="font">{{ $t('localization.selectLangageFont1') }}</span
					><span class="font1">{{ langageValue(selectLang) }}</span>
				</div> -->
				<div class="list">
					<div class="column">
						<span class="col-tit">United Kingdom</span>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '英国' && selectLang == 'en-US',
							}"
							@click="switchLanguage('en-US', '英国')"
							>English</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '英国' && selectLang == 'zh-CN',
							}"
							@click="switchLanguage('zh-CN', '英国')"
							>简体中文</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '英国' && selectLang == 'zh-TW',
							}"
							@click="switchLanguage('zh-TW', '英国')"
							>繁體中文</span
						>
					</div>
					<div class="column">
						<span class="col-tit">Cambodia</span>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '柬埔寨' && selectLang == 'en-KH',
							}"
							@click="switchLanguage('en-KH', '柬埔寨')"
							>កម្ពុជា។</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '柬埔寨' && selectLang == 'en-US',
							}"
							@click="switchLanguage('en-US', '柬埔寨')"
							>English</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '柬埔寨' && selectLang == 'zh-CN',
							}"
							@click="switchLanguage('zh-CN', '柬埔寨')"
							>简体中文</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '柬埔寨' && selectLang == 'zh-TW',
							}"
							@click="switchLanguage('zh-TW', '柬埔寨')"
							>繁體中文</span
						>
					</div>
					<div class="column">
						<span class="col-tit">INTERNATIONAL</span>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '其他地区' && selectLang == 'en-US',
							}"
							@click="switchLanguage('en-US', '其他地区')"
							>English</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '其他地区' && selectLang == 'zh-CN',
							}"
							@click="switchLanguage('zh-CN', '其他地区')"
							>简体中文</span
						>
						<span
							class="col-sub-tit"
							:class="{
								active: curCountry == '其他地区' && selectLang == 'zh-TW',
							}"
							@click="switchLanguage('zh-TW', '其他地区')"
							>繁體中文</span
						>
					</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import $ from 'jquery';
import { mapState } from 'vuex';
import { getQueryString, getCountryValue } from '@/utils/index';

$('.navbar-toggle').click(function() {
	if ($('#navbarSupportedContent').css('display') == 'none') {
		$('#navbarSupportedContent').css('display', 'block');
	} else if ($('#navbarSupportedContent').css('display') == 'block') {
		$('#navbarSupportedContent').css('display', 'none');
	}
});

$('.min-icon').click(function() {
	if ($('.min-navbar').css('display') == 'none') {
		$('.min-navbar').css('display', 'block');
	} else if ($('.min-navbar').css('display') == 'block') {
		$('.min-navbar').css('display', 'none');
	}
});

export default {
	data() {
		return {
			// curCountry: this.$store.state.country,
			countryList: [],
			lineImg1: require('../assets/imgs/header/line.png'),
			lineImg2: require('../assets/imgs/header/blank_line.png'),
			img1: require('../assets/imgs/home/logo_top.png'),
			img2: require('../assets/imgs/about/logo_white.png'),
			img3: require('../assets/imgs/contact/logo_contact.png'),
			indexImg: require('../assets/imgs/header/index.png'),
			indexImg02: require('../assets/imgs/header/index02.png'),
			aboutImg: require('../assets/imgs/header/about.png'),
			aboutImg02: require('../assets/imgs/header/about02.png'),
			serviceImg: require('../assets/imgs/header/service.png'),
			serviceImg02: require('../assets/imgs/header/service02.png'),
			newsImg: require('../assets/imgs/header/news.png'),
			newsImg02: require('../assets/imgs/header/news02.png'),
			contactImg: require('../assets/imgs/header/contact.png'),
			contactImg02: require('../assets/imgs/header/contact02.png'),

			langImg1: require('../assets/imgs/home/lang.png'),
			langImg2: require('../assets/imgs/contact/lang_con.png'),
			langImg3: require('../assets/imgs/service/lang_blank.png'),
			routeName: this.$route.name,
			value: this.$i18n.locale, //为了把下拉框的默认值和全局变量的值一致，以此实现载入页面时显示的语言和数据配置一致
			country1: require('../assets/imgs/home/china.png'),
			country2: require('../assets/imgs/home/en.png'),
			country3: require('../assets/imgs/home/tai.png'),
			country4: require('../assets/imgs/home/jian.png'),
			language: [
				{
					value: 'zh-CN',
					label: '中文简体',
					img: require('../assets/imgs/home/china.png'),
				},
				{
					value: 'en-US',
					label: 'English',
					img: require('../assets/imgs/home/en.png'),
				},
				{
					value: 'zh-TW',
					label: '中文繁體',
					img: require('../assets/imgs/home/tai.png'),
				},
				{
					value: 'en-KH',
					label: 'Khmer',
					img: require('../assets/imgs/home/jian.png'),
				},
			],
			selectPage: 0,
			selectLang: localStorage.getItem('DefaultLanguage') || 'zh-CN',
			drawer: false, // pc语言弹窗

			mobileDrawer: false, // mobile语言弹窗
		};
	},
	computed: {
		langageValue() {
			return (value) => {
				if (value == 'zh-CN') {
					return '简体中文';
				} else if (value == 'zh-TW') {
					return '繁體中文';
				} else if (value == 'en-US') {
					return 'English';
				} else if (value == 'en-KH') {
					return 'ភាសា​ខ្មែរ';
				}
			};
		},
		...mapState(['curCountry']),
	},
	mounted() {
		this.getCountryList();
		window.addEventListener('resize', () => {
			this.$nextTick(() => {
				this.mobileDrawer = false;
				this.drawer = false;
			});
		});
		let langUrl = getQueryString('lang');

		if (langUrl) {
			this.switchLanguage(langUrl, this.curCountry);
		} else {
			let value = localStorage.getItem('DefaultLanguage') || 'en-US';
			this.switchLanguage(value, this.curCountry);
		}
	},
	methods: {
		getCountryList() {
			let url = this.$store.state.baseUrl + '/realmNameList';
			this.$axios.get(url).then((res) => {
				if (res.data && res.data.length) {
					this.countryList = res.data;
					// this.countryList[0]['realmName'] = `http://192.168.0.117:8080/`;
					// this.countryList[1]['realmName'] = `http://192.168.0.117:8081/`;
					// this.countryList[2]['realmName'] = `http://192.168.0.117:8080/`;
				}
			});
		},
		goTop() {
			let timer = null;
			cancelAnimationFrame(timer);
			timer = requestAnimationFrame(function fn() {
				var oTop =
					document.body.scrollTop || document.documentElement.scrollTop;
				if (oTop > 0) {
					document.body.scrollTop = document.documentElement.scrollTop =
						oTop - 100;
					timer = requestAnimationFrame(fn);
				} else {
					cancelAnimationFrame(timer);
				}
			});
		},
		toggleClick() {
			this.selectPage = 1;
			document.getElementsByClassName(
				'phone-header-container'
			)[0].style.transform = 'translate(0, 0)';
			document.getElementsByClassName(
				'phone-header-container'
			)[0].style.transition = 'all 500ms';
		},
		closeClick() {
			this.selectPage = 0;
			document.getElementsByClassName(
				'phone-header-container'
			)[0].style.transform = 'translate(-101%, 0)';
		},
		/**
		 * 切换语言
		 * @param value string 语言值
		 * @param value string 国家
		 * @param isReplace boolean 是否替换url
		 */
		switchLanguage(value, country) {
			if (country == this.curCountry) {
				// 当前国家
				let targetUrl = this.updateQueryString(
					window.location.href,
					'lang',
					value
				);
				history.replaceState('', '', targetUrl);
				// window.location.replace(targetUrl);
			} else {
				// 非当前国家,跳转地址
				let findCountry = this._getCountryObj(country);
				if (findCountry) {
					let address = findCountry['realmName']; // 跳转网址
					let countryValue = this._getCountryValue(country);
					let jumpUrl = `${address}?lang=${value}&country=${countryValue}`;
					window.location.replace(jumpUrl);
				}
			}
			this.selectLang = value;
			this.$store.commit('setCountry', country);
			localStorage.setItem('DefaultLanguage', value);
			this.$emit('langChange', value);
			this._setTitle(value);

			let selList = document.getElementsByClassName('dropdown_a');
			let selectValue;

			for (let i = 0; i < selList.length; i++) {
				if (selList[i].innerHTML == selectValue) {
					selList[i].classList.add('selected');
				} else {
					selList[i].classList.remove('selected');
				}
			}

			this.drawer = false;
			this.mobileDrawer = false;
		},
		chooseLangage() {
			this.drawer = true;
		},
		chooseMobileLangage() {
			this.mobileDrawer = true;
		},

		// 获取hash下url参数
		getHashSearchParam(key) {
			const url = location.href;
			const hash = url.substring(url.indexOf('#') + 1);
			const searchIndex = hash.indexOf('?');
			const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : '';
			const usp = new URLSearchParams(search);
			return usp.get(key);
		},

		/*
		 * url 目标url
		 * arg 需要替换的参数名称
		 * arg_val 替换后的参数的值
		 * return url 参数替换后的url
		 */
		updateQueryString(url, arg, arg_val) {
			var pattern = arg + '=([^&]*)';
			var replaceText = arg + '=' + arg_val;
			if (url.match(pattern)) {
				var tmp = '/(' + arg + '=)([^&]*)/gi';
				tmp = url.replace(eval(tmp), replaceText);
				return tmp;
			} else {
				if (url.match('[\\?]')) {
					return url + '&' + replaceText;
				} else {
					return url + '?' + replaceText;
				}
			}
		},
		// 寻找国家
		_getCountryObj(countryName) {
			let findCountry = this.countryList.find(
				(item) => item.country == countryName
			);
			return findCountry;
		},
		// 设置标题
		_setTitle(value) {
			if (value == 'zh-CN') {
				this.$i18n.locale = 'zh-CN';
				document.title = '美奈美颜诊所 | Monet Beauty Clinic';
			} else if (value == 'en-US') {
				this.$i18n.locale = 'en-US';
				document.title = 'Monet Beauty Clinic | 美奈美颜诊所';
			} else if (value == 'zh-TW') {
				this.$i18n.locale = 'zh-TW';
				document.title = '美奈美顏診所 | Monet Beauty Clinic';
			} else if (value == 'en-KH') {
				this.$i18n.locale = 'en-KH';
				document.title = 'គ្លីនិកកែសម្ផស្ស Mui Ne | Monet Beauty Clinic';
			}
		},
		_getCountryValue(value) {
			let res = 'English';
			switch (value) {
				case '英国':
					res = 'English';
					break;
				case '柬埔寨':
					res = 'Cambodia';
					break;
				case '其他地区':
					res = 'INTERNATIONAL';
					break;
			}
			return res;
		},
	},
};
</script>

<style lang="scss" scoped>
.main-header {
	.header-page-container {
		.header-container {
			display: flex;
			justify-content: space-between;
			// align-items: center;
		}

		.header-page-top-img {
			margin-top: 34px;
		}

		.min-liHome:hover {
			a {
				font-weight: bold;
			}
		}

		.nav-white,
		.nav-contact {
			padding: 0;
			font-size: 16px;
			font-family: '微软雅黑';
		}

		.nav-contact {
			color: #7f628d !important;
		}

		.nav-white {
			color: #fff !important;
		}

		.nav-blank {
			color: #000 !important;
		}

		.active,
		.contact-active,
		.blank-active {
			position: relative;
			font-weight: bold;
			text-align: center;
		}

		.active::before,
		.contact-active::before,
		.blank-active::before {
			width: 30px;
			height: 2px;
			margin: auto;
			content: '';
			position: absolute;
			top: 40px;
			left: 0;
			bottom: 0;
			right: 0;
		}

		.active::before {
			background-color: #fff;
		}

		.contact-active::before {
			background-color: #7f628d;
		}

		.blank-active::before {
			background-color: #000;
		}

		.nav > li > a {
			padding: 0 !important;
		}

		.navbar {
			padding: 0;
		}

		.navbar-brand {
			height: 0 !important;
		}

		.navbar-toggler-icon {
			background-color: transparent;
			border-radius: 1px;
		}

		.navbar-inverse {
			background-color: transparent !important;
			border-color: transparent !important;
		}

		a:hover {
			text-decoration: none;
		}

		.language-style {
			margin-top: 40px;
			display: flex;
			align-items: center;
			cursor: pointer;
			.lang-blank {
				color: #000 !important;
			}
			.lang-white {
				color: #fff !important;
			}
			.lang-purple {
				color: #7f628d !important;
			}

			.lang-font-icon {
				width: 24px;
				height: 24px;
			}
			.lang-font {
				font-size: 14px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #272729;
			}
		}

		.min-liHome:hover {
			cursor: pointer;
		}
	}

	.phone-header-container {
		position: fixed;
		top: 0;
		left: 0;
		right: auto;
		width: 100%;
		height: 100vh;
		display: flex;
		z-index: 999999;
		transform: translate(-101%, 0);

		.hidden-div {
			width: 50%;
			min-height: 600px;
			background-color: rgba(40, 33, 41, 0.4);
		}

		.phone-header {
			width: 50%;
			height: 100%;
			padding-top: 56px;
			overflow-y: hidden;
			background-color: #281429;

			.phone-top {
				padding-right: 20px;
				display: flex;
				justify-content: flex-end;

				.phone-top-x {
					cursor: pointer;
				}
			}

			.phone-main {
				text-align: left;

				ul {
					padding: 0;
					margin: 0;

					.phone-main-every a:hover {
						color: #fff;
						font-weight: bold;
					}
				}
			}

			.oldType {
				height: 100%;
				display: flex;
				align-items: center;
				padding-left: 23px;
				color: #beb1cb;
				border-left: 3px solid #281429;
				font-size: 12px;

				.every-img {
					width: 15px;
					height: 15px;
					margin-right: 16px;
					vertical-align: middle;
				}

				span {
					vertical-align: middle;
				}
			}

			.phoneClick {
				color: #fff;
				border-left: 3px solid #9e52a1;
				background: url('../assets/imgs/header/selBack.png');
				box-sizing: border-box;
			}
		}
	}
}

.dropdown_a {
	color: #aaa !important;
	font-size: 14px;
}

.selected {
	color: #7f628d !important;
}

.phone-lang {
	text-align: left;
	color: #2c3e50;

	.lang-icon {
		display: flex;

		p {
			margin-left: 5px;
			color: #2c3e50;
		}
	}
}

@media screen and (min-width: 1781px) {
	.header-page-container {
		padding: 0 208px 0 222px;

		.top-computer {
			display: block;
		}

		.top-phone {
			display: none;
		}

		.nav-margin {
			margin-left: 50px;
		}

		.header-page-top-nav {
			margin-top: 40px;
			display: flex;
		}

		.nav-item {
			line-height: 36px;
			text-align: center;
		}

		.navbar-nav {
			float: right !important;
		}

		.lang-icon {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 1499px) and (max-width: 1780px) {
	.header-page-container {
		padding: 0 150px 0 170px;

		.top-computer {
			display: block;
		}

		.top-phone {
			display: none;
		}

		.nav-margin {
			margin-left: 50px;
		}

		.lang-icon {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1780px) {
	.header-page-container {
		.top-computer {
			display: block;
		}

		.top-phone {
			display: none;
		}

		.header-page-top-nav {
			margin-top: 40px;
			display: flex;
		}

		.nav-item {
			line-height: 36px;
			text-align: center;
		}

		.navbar-nav {
			float: right !important;
		}

		.lang-icon {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
	.header-page-container {
		padding: 0 80px 0 80px;

		.top-computer {
			display: block;
		}

		.top-phone {
			display: none;
		}

		.nav-margin {
			margin-left: 40px;
		}

		.lang-icon {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.header-page-container {
		padding: 0 40px 0 40px;

		.top-computer {
			display: block;
		}

		.top-phone {
			display: none;
		}

		.nav-margin {
			margin-left: 20px;
		}

		.header-page-top-nav {
			margin-top: 40px;
			display: flex;
		}

		.nav-item {
			text-align: center;
		}

		.navbar-nav {
			float: right !important;
		}

		.lang-icon {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.header-page-container {
		padding: 0 10px;

		.top-computer {
			display: none;
		}

		.top-phone {
			display: block;
		}

		.header-page-top-nav {
			position: relative;
		}

		.navbar-collapse {
			position: absolute;
			right: 25px;
			z-index: 999;
		}

		.header-page-top-img {
			margin-top: 24px !important;
		}

		.language-style {
			display: none !important;
		}

		.header-page-top-nav {
			margin-top: 80px;
			display: flex;
		}

		.navbar-toggler {
			position: absolute;
			top: 25px;
			left: 0;
			padding: 0;
		}

		.img-responsives {
			width: 130px;
			height: auto;
		}
	}

	.phone-header {
		.phone-top {
			.phone-top-x {
				font-size: 20px;

				.phone-top-img {
					width: 14px;
					height: 14px;
				}
			}
		}

		.phone-main {
			.phone-main-every {
				height: 46px;
				line-height: 46px;
				margin-bottom: 11px;

				a {
					font-size: 16px;
				}
			}
		}
	}

	.phone-lang {
		margin-top: 28px;

		.lang-icon {
			p {
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.header-page-container {
		height: 60px;
		padding: 0 10px;

		.top-computer {
			display: none;
		}

		.top-phone {
			display: block;
		}

		.header-page-top-nav {
			position: relative;
		}

		.navbar-collapse {
			position: absolute;
			right: 25px;
			z-index: 999;
		}

		.header-page-top-img {
			margin-top: 0 !important;
			padding-top: 17px;
		}

		.language-style {
			display: none !important;
		}

		.header-page-top-nav {
			margin-top: 80px;
			display: flex;
		}

		.navbar-toggler {
			position: absolute;
			top: 20px;
			left: 0;
			padding: 0;
		}

		.img-responsives {
			width: 110px;
			height: auto;
		}
	}

	.phone-header {
		.phone-top {
			.phone-top-x {
				font-size: 20px;

				.phone-top-img {
					width: 14px;
					height: 14px;
				}
			}
		}

		.phone-main {
			.phone-main-every {
				height: 46px;
				line-height: 46px;
				margin-bottom: 11px;
				text-align: center;

				a {
					font-size: 15px;
					letter-spacing: 1;
				}
			}
		}
	}

	.phone-lang {
		margin-top: 18px;

		.lang-icon {
			p {
				padding-top: 3px;
				font-size: 13px;
			}
		}
	}
}

.langage-pc-box {
	width: 100%;
	height: 738px;
	background: #fff;

	.title {
		font-size: 38px;
		font-family: ' Microsoft YaHei-Regular, Microsoft YaHei';
		font-weight: 400;
		color: #000000;
	}
	.font-wrap {
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;

		.font {
			color: #85888c;
		}
		.font1 {
			color: #000;
			margin-left: 4px;
		}
	}
	.line {
		width: 40px;
		height: 3px;
		background-color: #863e89;
		margin: 44px auto;
	}
	.list {
		display: flex;
		justify-content: center;
		margin-top: 30px;
		.column {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-right: 200px;

			.col-tit {
				font-size: 26px;
				font-family: Arial-Regular, Arial;
				font-weight: 400;
				color: #863e89;
				margin-bottom: 40px;
				cursor: pointer;
			}
			.col-sub-tit {
				margin-bottom: 20px;
				cursor: pointer;
				&.active {
					color: #863e89;
				}
			}
		}
		.column:last-child {
			margin-right: 0;
		}
	}
}

.langage-mobile-box {
	text-align: left;
	padding: 0 15px;
	box-sizing: border-box;
	.title {
		font-size: 22px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #272729;
	}
	.line {
		width: 30px;
		height: 2px;
		background: #863e89;
		margin-top: 24px;
	}
	.font-wrap {
		margin-top: 25px;
		font-size: 12px;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #85888c;
		.font1 {
			color: #000;
			margin-left: 6px;
		}
	}
	.list {
		.column {
			.col-tit {
				font-size: 17px;
				font-family: PingFang SC-Bold, PingFang SC;
				font-weight: bold;
				color: #863e89;
				line-height: 17px;
				margin-top: 30px;
			}
			.col-sub {
				margin-top: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid #f2f3f5;
			}
			.col-sub-tit {
				font-size: 14px;
				font-family: PingFang SC-Medium, PingFang SC;
				font-weight: 500;
				color: #272729;
				margin-right: 24px;
				&.active {
					color: #863e89;
				}
			}
		}
	}
}
</style>
<style lang="scss">
#header-page {
	.navbar-light .navbar-toggler {
		color: #fff;
		border: none;
	}

	.navbar-light .navbar-toggler-icon {
		background-image: none;
	}
}
</style>
