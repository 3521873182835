module.exports = {
	localization: {
		help01: 'Help every customer to achieve their beauty dreams safely.',
		help02: '',
		contactType: 'ព័ត៌មានលម្អិតទំនាក់ទំនង',
		checkUsFont01: 'ស្វែងរក និងទំនាក់ទំនងយើងតាមរយះ ',
		checkUsFont02: ' ឬទូរស័ព្ទលេខ ',
		checkUsFont03: '',
		homeFont: 'គេហទំព័រ',
		aboutFont: 'អំពីពួកយើង',
		treatmentFont: 'កញ្ចប់សេវាកម្ម',
		changeFont: 'ចែករំលែកការផ្លាស់ប្តូរ',
		newsFont: 'ព័ត៌មានវេជ្ជសាស្រ្ត',
		contactFont: 'ទាក់ទងមកពួកយើង',
		contactUs: 'ទាក់ទងមកពួកយើង',
		bannerOne: 'ភាពស្រស់ស្អាតរបស់អ្នក ចាប់ផ្តើមនៅទីនេះ',
		bannerOneEn: 'Your beauty begins here.',
		addrFont: 'អាសយដ្ឋាន',
		addrDetailsFont:
			'ភូមិ១២ សង្កាត់ទន្លេបាសាក់ ខ័ណ្ឌចំការមន រាជធានីភ្នំពេញ។ ក្បែរផ្សារទំនើប អ៊ីអនមលភ្នំពេញ',
		addrDetailsLonDonFont: 'W9 1PP ផ្ទះលេខ56 ផ្លូវ Maida Vale ទីក្រុងឡុនដុន',
		addrDetailsHongKongFont: '7Aលេខ68 ផ្លូវ Kimberley, Tsim Sha Tsui, ហុងកុង',
		addrDetailsPHFont: 'បុរីប៉េងហួតបឹងស្នោរ',

		telFont: 'លេខទំនាក់ទំនង',
		emailFont: 'អ៊ីមែល',
		copyrightFont: 'ម៉ូណែត គ្លីនិកប៊ីយូធីខេមបូឌារក្សារសិទ្ធគ្រប់យ៉ាង',
		brandStoryFont: 'About Us',
		brandStoryOneFont:
			'ម៉ូណែត គ្លីនិកប៊ីយូធីខេមបូឌាដោយប្រកាន់ខ្ជាប់នូវឧត្តមគតិយ៉ាងម៉ឹងម៉ាត់នៃការផ្លាស់ប្តូរជំនាញបច្ចេកទេសនិងសេវាកម្មប្រកបដោយវិជ្ជាជីវៈ។ ការបង្កើតមន្ទីរពេទ្យនេះផ្តល់ជូននូវសេវាកម្មសោភ័ណភាពវេជ្ជសាស្រ្តប្រកបដោយភាពទាន់សម័យនិង សម្រាប់អ្នកដែលស្រឡាញ់សម្រស់។ ម៉ូណែត ប៊ីយូធីតែងតែ “ ជួយមិត្តទាំងអស់ដែលស្រលាញ់ភាពស្រស់ស្អាត ដើម្បីទទួលបាននូវសេចក្តីប្រាថ្នា និងភាពស្រស់ស្អាតរបស់ពួកគេដោយទំនុកចិត្តនិងសុវត្ថិភាព” ដែលជាបេសកកម្មនៃការអភិវឌ្ឍន៍របស់គ្លីនិក!',
		brandStoryTwoFont:
			'ម៉ូណែត គ្លីនិកប៊ីយូធីខេមបូឌាជាមន្ទីរពេទ្យវះកាត់ខ្សែសង្វាក់ដែលមានគុណភាពតែងតែធ្វើតាមផ្នត់គំនិតនៃសេវាកម្ម "ធម្មជាតិ សុវត្ថិភាពនិងស្រស់ស្អាត" ។ បង្កើតជំនាញវិជ្ជាជីវៈមន្ទីរសុខាភិបាលនិងមន្ទីរជំនួយជាង ១០ កន្លែងដូចជាមជ្ឈមណ្ឌលវះកាត់សម្ផស្ស ស្បែក មជ្ឈមណ្ឌលវះកាត់ខ្នាតតូច មជ្ឈមណ្ឌលប្រឆាំងភាពចាស់ និងការថែទាំសុខភាព។ ល។',
		brandStoryDetailsFont: 'ព័ត៌មានលម្អិត',
		aboutUsFont: 'អំពីពួកយើង',
		aboutUsConFont:
			'Monet Beauty Clinic is one of the largest medical aesthetic clinic in Europe offering a top-tier luxury skin treatment experience and utilising the latest FDA-approved facial, body, and skin technologies. Rooted in dermatological expertise and research, the headquarters has been based in Hong Kong since 2010. Monet Beauty Clinic is committed to providing the highest quality services, boasting decades of clinical experience, with a team of doctors hailing from star-studded backgrounds including China, Japan, South Korea, and the UK. We specialise in catering to Asian facial contours and creating an Asian aesthetic style, frequently participating in Asian medical aesthetic conferences for technical exchange.',
		aboutUsConSecondFont:
			'Monet Beauty Clinic integrates the most advanced non-surgical treatment methods from the United States, Europe, South Korea, and Japan. Our goal is to provide you with personalised treatment plans tailored to your specific needs. We excel in skin management, offering services including skin brightening, anti-aging, and skin rejuvenation. Additionally, we have achieved remarkable results in treatments such as fat reduction, facial injections, thread lifting, and hair treatments.',
		aboutUsConThirdFont:
			'To date, Monet Beauty Clinic has served over 30,000 clients from all over the world, earning a solid reputation while delivering luxurious services to its clients.',
		OurServicesFont: 'កញ្ចប់សេវាកម្ម',
		raiseHairFeatureFont:
			'លក្ខណៈពិសេស ：គ្រឿងសំអាងគឺជាសារធាតុឬផលិតផលដែលត្រូវបានប្រើដើម្បីលើកកម្ពស់ ឬផ្លាស់ប្តូររូបរាងនៃមុខឬក្លិនក្រអូបនិងសុខភាពនៃរាងកាយ។ គ្រឿងសំអាងជាច្រើនត្រូវបានរចនាឡើងយ៉ាងពិសេសសម្រាប់លាបលើមុខសក់និងដងខ្លួន…',
		moreFont: 'ស្វែងយល់បន្ថែម',
		brandShopFont: 'បញ្ជរផលិតផល',
		fourthTitleFont: 'ក្រុមអ្នកជំនាញ',
		fourthTitleConFont:
			'ប្រមូលផ្តុំម៉ូដែលឧស្សាហកម្មដើម្បីបង្កើតការផ្លាស់ប្តូរដ៏ឆើតឆាយ',
		consultingFont: 'អ្នកជំនាញប្រឹក្សារ',
		appointmentFont: 'ណាត់ជួបឥឡូវនេះ',
		fifthTitleFont: 'អាសយដ្ឋានគ្លីនិក',
		shareFont: 'ចែករំលែកការផ្លាស់ប្តូរ',
		newsInfoFont: 'ព័ត៌មានវេជ្ជសាស្រ្ត',
		timeFont: 'ពេលវេលាបច្ចុប្បន្នភាព：',

		jinBianFont: 'Phnom Penh Branch',
		lonDonFont: 'London Branch',
		jinBianPHFont: 'Phnom Penh PH Branch',
		hongKongFont: 'Hong Kong Branch',

		consultFont: 'ការប្រឹក្សា',
		nameFont: 'គោត្តនាម',
		telephoneFont: 'លេខទំនាក់ទំនង',
		consultContainerFont: 'មាតិកាពិគ្រោះយោបល់',
		nameSureFont: 'ឈ្មោះមិនអាចទទេ',
		phoneSureFont: 'លេខទំនាក់ទំនងមិនអាចទទេ',
		contentSureFont: 'មាតិកាពិគ្រោះយោបល់មិនអាចទទេ',
		catalogFont: 'តារាងមាតិកា',
		langFont: 'ភាសា',

		specialProjectFont: 'គម្រោងពិសេស',
		fightDeclineAscensionFont: 'ការប្រឆាំងភាពចាស់',
		fightDeclineAscensionFont01: 'ការប្រឆាំងភាពចាស់',
		fightOneFont: 'Fotona 4D Pro',
		fightTwoFont:
			'Ultherapy ព្យាបាលផ្នែកមុខដោយកាំបិត Ultrasound ជ្រាបចូលយ៉ាងជ្រៅរបស់SMAS',
		fightTenFont: 'ENDYMED 3Deep radiofrequency tightening & lift',
		fightThreeFont: 'Variety American Threadlift',
		fightFourFont: 'ការថែរក្សាកោសិកាស្បែករបស់ជប៊ុនដោយពន្លឺទឹកឥតម្ជុល ',
		fightFiveFont: 'ការចាក់ពន្លឺទឹកឥតម្ជុលដែលមិនមានរបួសដោយSEYO	របស់អាល្លឺមង់',
		fightSixFont: 'ប្រឆាំងភាពចាស់ជ្រីវជ្រួញលើផ្ទៃមុខ ដោយFiorman',
		fightSevenFont: 'ការថែទាំព្យាបាលផ្នែកមុខដោយLa Mer',
		fightEightFont: 'ការថែទាំប្រឆាំងភាពចាស់ Genaissance ដោយLa Mer',
		fightNineFont: 'Thermage Flx បំបាត់ស្នាមជ្រីវជ្រួញជំនាន់ទី ៥',
		fightElevenFont: 'BTL Ultra360', // ff

		whiteningBeautyCreamFont: 'ធ្វើឱ្យស្បែកសនិងបំបាត់សន្លាក',
		whiteningBeautyCreamFont01: 'ធ្វើឱ្យស្បែកសនិងបំបាត់សន្លាក',
		whiteningOneFont: 'បំបាត់ស្នាមជ្រីវជ្រួញលើផ្ទៃមុខដោយ Picoway',
		whiteningTwoFont: 'ធ្វើឱ្យរាងកាយសភ្លឺរលោងដោយPicoway',
		whiteningThreeFont:
			'ធ្វើឱ្យស្បែកស្រស់ថ្លាភ្លឺរលោងដោយម៉ាស៊ីនAGLEX របស់ជប៊ុន',
		whiteningFourFont:
			'ម៉ាស់កំបោក្តៅធ្វើស្បែកសរនិងប្រឆាំងជាតិស្កររបស់ផលិតផលPOLA',
		whiteningFiveFont: 'ព្យាបាលស្បែកមុខឱ្យសដោយផលិតផលValmont',
		whiteningSixFont: 'ថែរក្សាស្បែកសនិងធ្វើឱ្យស្បែកស្រស់ថ្លាដោយផលិតផលFillMed',
		whiteningSevenFont: 'ការចាក់ថ្នាំស្បែកសរប្រភេទVC របស់ជប៊ុន',

		cleanTheFillingWaterFont: 'សំអាតនិងផ្តល់សំណើម',
		cleanTheFillingWaterFont01: 'សំអាតនិងផ្តល់សំណើម',
		cleanOneFont:
			'ការសម្អាតស្បែក និងផ្ដល់សំណើមពីស្រទាប់ស្បែកខាងក្នុងដោយ Hydrafacial',
		cleanTwoFont: 'ការបន្សុទ្ធមុខនិងការថែរក្សាសំណើមពីផលិតផល La Mer',
		cleanThreeFont: 'ការថែរក្សាស្បែកមុខមានសំណើមពីផលិតផល Valmont',
		cleanFiveFont: 'ការថែរក្សាស្បែកមុខមានសំណើមពីផលិតផល Fillmed',

		shrinkPoresFont: 'បង្រួមរន្ធញើស',
		shrinkPoresFont01: 'បង្រួមរន្ធញើស',
		shrinkOneFont: 'Picoway បង្រួមរន្ធញើស',
		shrinkTwoFont: 'Gold microneedle ដោយម៉ាស៊ីនENDYMED របស់អ៊ីស្រាអែល',

		raiseHairFont: 'បណ្ដុះនិងចឹញ្ចឹមសក់',
		raiseHairFont01: 'បណ្ដុះនិងចឹញ្ចឹមសក់',
		hairOneFont: 'បណ្ដុះសក់ដោយម៉ាស៊ីនឡាស៊ែរ Fotona',
		hairTwoFont: 'ការគ្រប់គ្រងស្បែកក្បាលដោយ Carbonated របស់ជប៊ុន',
		hairThreeFont: 'ការថែទាំសក់ដោយផលិតផល Keratese',
		hairFourFont: 'Revitacare Haircare',

		managementFont: 'ការគ្រប់គ្រងរាងកាយ',
		managementFont01: 'ការគ្រប់គ្រងរាងកាយ',
		managementOneFont: 'បង្កើនសាច់ដុំនិងកាត់បន្ថយជាតិខ្លាញ់ដោយ EMSCULPT',
		managementTwoFont: 'ការចាក់រំលាយជាតិខ្លាញ់ដោយ Liposonix',
		managementThreeFont: 'កាំបិតរលាយជាតិខ្លាញ់របស់ប៉ារីសប្រទេសបារាំង',

		privateRaiseFont: 'ថែទាំតំបន់ឯកជន',
		privateRaiseFont01: 'ថែទាំតំបន់ឯកជន',
		privateOneFont: 'ការព្យាបាលតំបន់ឯកជនដោយម៉ាស៊ីនFotona Smooth',
		privateTwoFont: 'ការធ្វើPicowayឱ្យតំបន់ឯកជនព័ណស៊ីជំពូរ',
		privateFourFont: 'ឧបករណ៌ថែទាំសុខភាពស្បូន​ ប្រទេសជប៉ុន',

		injectionFont: 'ចាក់ថ្នាំសំរករាង',
		injectionFont01: 'ចាក់ថ្នាំសំរករាង',
		injectionOneFont: 'ចាក់Botox មានរាងស្លីមនិងបំបាត់ស្នាមជ្រីវជ្រួញលើផ្ទៃមុខ',
		injectionTwoFont: 'ការបំពេញអាស៊ីដ Hyaluronic ដោយ Juvederm',
		injectionThreeFont: 'ការបំពេញអាស៊ីដ Hyaluronic ដោយ Restylane',
		injectionFourFont: 'ការចាក់ Ellansរក្សារភាពវៃក្មេងរបស់ហូល្លង់',
		injectionFiveFont: 'ការឆ្លាក់ខ្សែលស់ច្រមុះ ELLA របស់អាល្លឺម៉ង',

		otherFont: 'គម្រោងផ្សេងទៀត',
		otherFont01: 'គម្រោងផ្សេងទៀត',
		otherOneFont: 'សំអាតរោមដោយAlma Freezing point laser hair removal',
		otherTwoFont: 'PicoWayលុបសាក់លើដងខ្លួន',
		otherThreeFont: 'Picoway លុបបំបាត់រង្វង់ភ្នែកខ្មៅ',
		otherFourFont: 'បំបាត់ស្នាមជ្រីវជ្រួញនៅក(បឿ-ក)ដោយផលិតផលHearty',
		otherFiveFont: 'ការបណ្ដុះកោសិការនិងការថែរក្សាស្បែកមុខដោយFillmed',
		otherSixFont:
			'ការធ្វើចិញ្ចើមនិងបបូរមាត់ជាប់បានរយះពេលយូរស្ដង់ដារ ជប៊ុននិងកូរ៉េ',
		otherSevenFont: 'សេរ៉ូមបង្កើនភាពស៊ាំនិងបន្សាបជាតិពុលក្នុងថ្លើមរបស់ ជប៊ុន',
		otherEightFont: 'ថែរក្សាភ្នែកប្រឆាំងភាពចាស់ពីផលិតផល Lamer',

		membersOnlyFont: '៤. ផ្តាច់មុខចំពោះសមាជិក',
		membersFont: 'Open to members only',
		membersOneFont: 'Japanese Miss eye dor eyelash grafting',
		membersTwoFont: 'Japanese mao gel Manicure',
		membersThreeFont: 'VALMONT body oil massage',
		membersFourFont: 'MTG Scalp Health Management',

		skinProblemFont: 'Skin Problem Repair',
		skinProblemOneFont: 'LDM-MED',
		skinProblemTwoFont: 'LUTRONIC Healite IIC',
		skinProblemThreeFont: 'Lumenis M22 AOPT ',

		faceAndBodyFont: 'Face And Body Reshaping',

		brandListFont: 'តារាងផលិតផល',
		brandFont01: 'LA MER',
		brandFont02: 'FILLMED',
		brandFont03: 'VALMONT',
		brandFont04: 'POLA',
		brandFont05: 'AXXZIA',
		brandFont06: 'hydrafacial',
		brandFont07: 'PicoWay',
		brandFont08: 'Fotona',
		brandFont09: 'ULTHERA',
		brandFont10: 'EMSCULPT',
		brandFont11: 'Liposonix',
		brandFont12: 'Alma',
		brandFont13: 'AGLEX',
		brandFont14: 'BOTOX',
		brandFont15: 'JUVEDERM',
		brandFont16: 'RESTYLANE',
		brandFont17: 'thermage',
		brandFont18: 'ENDYMED',
		brandFont19: 'LDM-MED',
		brandFont20: 'Lutronic',
		brandFont21: 'BTL',
		brandFont22: 'Lumenis',

		checkFont: 'ស្វែងរក',
		placeFont: 'សូមបំពេញបន្ថែមដើម្បីស្វែងរក',
		selectLangageFont: 'ជ្រើស​តំបន់ និង​ភាសា​របស់​អ្នក',
		selectLangageFont1: 'បាន​ជ្រើស​បច្ចុប្បន្ន',
		selectLangageFont2: 'អង់គ្លេស',
		selectLangageFont3: 'កម្ពុជា',
		selectLangageFont4: 'អន្តរជាតិ',

		selectLangFont1: 'អង់គ្លេស',
		selectLangFont2: 'ចិន​ធម្មតា',
		selectLangFont3: 'ប្រពៃណី​ចិន',
		selectLangFont4: 'កម្ពុជា។',
		companyAddr: '56 Maida Vale, London, UK W9 1PP',
		wechatFont: 'Wechat',
		xiaohongshuFont: 'Xiaohongshu',
		address: 'Address',
		telephone: 'Phone Number',
		phone: 'Telephone Number',
	},
};
