module.exports = {
	localization: {
		help01: '幫助每一位愛美的朋友',
		help02: '安全放心的達成美麗心願',
		contactType: '聯&nbsp;&nbsp;繫&nbsp;&nbsp;方&nbsp;&nbsp;式',
		checkUsFont01: '通過搜索',
		checkUsFont02: '或',
		checkUsFont03: '找到我們',
		homeFont: '首頁',
		aboutFont: '關於我們',
		treatmentFont: '服務項目',
		changeFont: '蛻變分享',
		newsFont: '醫美資訊',
		contactFont: '聯絡我們',
		contactUs: '聯絡我們',
		bannerOne: '你的美，從這裡開始',
		bannerOneEn: 'Your beauty begins here.',
		addrFont: '地址',
		addrDetailsFont:
			'金邊市桑園區百色河分區12村聯合辦公大樓（靠近永旺商場後門）',
		addrDetailsLonDonFont: '英國倫敦麥達維爾56號 W9 1PP',
		addrDetailsHongKongFont: '香港，尖沙咀，金巴利街68號，7A',
		addrDetailsPHFont: '金邊市鐵橋頭區炳發城',

		telFont: '電話',
		emailFont: '電郵',
		copyrightFont: '柬埔寨美奈美顏診所版權所有',
		brandStoryFont: '關&nbsp;&nbsp;於&nbsp;&nbsp;我&nbsp;&nbsp;們',
		brandStoryOneFont:
			'柬埔寨美奈美顏診所所秉承只要專業定制的技術及服務理念，醫院的成立為更多愛美者提供了更專業、更時尚、更安全的醫美服務。美奈美顏始終把“幫助每一位愛美的朋友安全放心的達成美麗心願”作為醫院發展的使命！',
		brandStoryTwoFont:
			'柬埔寨美奈美顏診所是品質連鎖整形美容醫院，始終遵循“自然、安全、美觀”的服務理念。專業開設整形美容中心、皮膚美容中心、微整形中心、抗衰老中心、健康管理等十餘項臨床科室和輔助科室',
		brandStoryDetailsFont: '查看詳情',
		aboutUsFont: '關於我們',
		aboutUsConFont:
			'倫敦Monet Beauty Clinic所是歐洲最大的微整形醫美診所之一，擁有頂級奢華的護膚體驗，使用最新的FDA面部，身體和皮膚技術。源於皮膚科的專業知識和研究，自2010年起總部設立在香港。 Monet Beauty Clinic專注於最優質的服務，我們擁有數十年的臨床經驗，醫生團隊來自包括中國、日本、韓國和英國等明星醫師。我們專業提供針對亞洲人面部輪廓，打造亞洲審美風格，經常參加亞洲醫美大會並進行技術交流。',
		aboutUsConSecondFont:
			'美奈美顏結合了美國、歐洲、韓國和日本最先進的非手術治療方法。我們的目標是為您提供定制化的治療方案，量身定制您的需求。我們擅長皮膚管理，包括美白、抗衰老和皮膚年輕化等服務。此外，我們在減脂、面部注射、拔絲和植髮等治療方面也做出了非常出色的成績。',
		aboutUsConThirdFont:
			'時至今日,美奈美顏已經服務了來自世界各地的30000多名客戶，為客戶提供奢華服務的同時,也獲得了良好的聲譽。',

		OurServicesFont: '服務項目',
		raiseHairFeatureFont:
			'項目特色：化妝品是一種物質或產品，用來增強或改變面部的外觀或身體的香味與質感。許多化妝品是專為適用於面部、頭髮和身體而設計的...',
		moreFont: '查看更多',
		brandShopFont: '品&nbsp;&nbsp;牌&nbsp;&nbsp;門&nbsp;&nbsp;店',
		fourthTitleFont: '專 家 團 隊',
		fourthTitleConFont: '匯聚行業楷模 鑄就美麗蝶變',
		consultingFont: '咨詢專家',
		appointmentFont: '立即預約',
		fifthTitleFont: '診所地址',
		shareFont: '蛻變分享',
		newsInfoFont: '醫美資訊',
		timeFont: '更新時間：',
		contactUsFont: '',

		jinBianFont: '金邊永旺診所',
		lonDonFont: '倫敦診所',
		jinBianPHFont: '金邊PH診所',
		hongKongFont: '香港診所',

		consultFont: '咨詢',
		nameFont: '姓名',
		telephoneFont: '聯繫電話',
		consultContainerFont: '咨詢內容',
		nameSureFont: '姓名不能為空',
		phoneSureFont: '聯繫電話不能為空',
		contentSureFont: '咨詢內容不能為空',
		catalogFont: '目錄',
		langFont: '語言',

		specialProjectFont: '特&nbsp;&nbsp;色&nbsp;&nbsp;項&nbsp;&nbsp;目',
		fightDeclineAscensionFont: '抗衰提升',
		fightDeclineAscensionFont01: '抗&nbsp;&nbsp;衰&nbsp;&nbsp;提&nbsp;&nbsp;升',
		fightOneFont: 'Fotona 4D Pro',
		fightTwoFont: 'Ultherapy超聲刀面部SMAS深層提升',
		fightTenFont: 'ENDYMED 3Deep相控射頻緊緻提升',
		fightThreeFont: '美國多種線材線雕提升',
		fightFourFont: '日本曉姿幹細胞無針水光護理',
		fightFiveFont: '德國SEYO無創頭皮水光品相機器人',
		fightSixFont: '菲歐曼面部極致抗衰護理',
		fightSevenFont: '海藍之謎面部修護提升護理',
		fightEightFont: '海藍之謎鎏金煥顏抗衰護理',
		fightNineFont: '第五代熱瑪吉緊膚除皺',
		fightElevenFont: 'BTL Ultra360', // ff

		whiteningBeautyCreamFont: '美白祛斑',
		whiteningBeautyCreamFont01: '美&nbsp;&nbsp;白&nbsp;&nbsp;祛&nbsp;&nbsp;斑',
		whiteningOneFont: 'Picoway超皮秒面部祛斑',
		whiteningTwoFont: 'Picoway超皮秒身體美白',
		whiteningThreeFont: '日本AGLEX嫩膚美白艙',
		whiteningFourFont: 'POLA黑BA抗糖美白溫感石膏',
		whiteningFiveFont: '法爾曼美白面部護理',
		whiteningSixFont: '菲歐曼面部皙白煥膚護理',
		whiteningSevenFont: '日本高濃度VC美白針',

		cleanTheFillingWaterFont: '清潔補水',
		cleanTheFillingWaterFont01: '清&nbsp;&nbsp;潔&nbsp;&nbsp;補&nbsp;&nbsp;水',
		cleanOneFont: '海菲秀深層清潔補水嫩膚',
		cleanTwoFont: '海藍之謎面部淨化補水護理',
		cleanThreeFont: '法爾曼面部水潤補濕護理',
		cleanFiveFont: '菲歐曼面部水潤保濕護理',

		shrinkPoresFont: '收縮毛孔',
		shrinkPoresFont01: '收&nbsp;&nbsp;縮&nbsp;&nbsp;毛&nbsp;&nbsp;孔',
		shrinkOneFont: 'Picoway超皮秒收縮毛孔',
		shrinkTwoFont: '以色列ENDYMED黃金微針',

		raiseHairFont: '生發養髮',
		raiseHairFont01: '生&nbsp;&nbsp;發&nbsp;&nbsp;養&nbsp;&nbsp;髮',
		hairOneFont: 'Fotona激光生發機器人',
		hairTwoFont: '日本碳酸泉頭皮管理',
		hairThreeFont: '卡詩魚子醬護髮',
		hairFourFont: '絲麗生發動能素HAIRCARE',

		managementFont: '身材管理',
		managementFont01: '身&nbsp;&nbsp;材&nbsp;&nbsp;管&nbsp;&nbsp;理',
		managementOneFont: 'EMSCULPT美修斯增肌減脂',
		managementTwoFont: 'Liposonix 熱立塑溶脂塑形',
		managementThreeFont: '法國巴黎時光溶脂刀',

		privateRaiseFont: '私密維養',
		privateRaiseFont01: '私&nbsp;&nbsp;密&nbsp;&nbsp;維&nbsp;&nbsp;養',
		privateOneFont: 'Fotona Smooth私密機器人',
		privateTwoFont: 'Picoway超皮秒私密漂紅',
		privateFourFont: '日本暖宮護理倉',

		injectionFont: '注射塑形',
		injectionFont01: '注&nbsp;&nbsp;射&nbsp;&nbsp;塑&nbsp;&nbsp;形',
		injectionOneFont: '保妥適肉毒瘦臉瘦身去皺',
		injectionTwoFont: '喬雅登玻尿酸填充',
		injectionThreeFont: '瑞藍玻尿酸填充',
		injectionFourFont: '荷蘭Ellanse少女針',
		injectionFiveFont: '德國Ella線雕隆鼻',

		otherFont: '其他項目',
		otherFont01: '其&nbsp;&nbsp;他&nbsp;&nbsp;項&nbsp;&nbsp;目',
		otherOneFont: 'Alma冰點激光脫毛',
		otherTwoFont: 'PicoWay超皮秒洗紋身',
		otherThreeFont: 'Picoway超皮秒去黑眼圈',
		otherFourFont: '嗨體去頸紋',
		otherFiveFont: '菲歐曼面部舒敏修復護理',
		otherSixFont: '日韓半永久眉眼唇',
		otherSevenFont: '日本肝臟排毒增強抵抗力點滴',
		otherEightFont: '海藍之謎抗衰眼部護理',

		membersOnlyFont: '會員專享',
		membersFont: '只針對會員開放',
		membersOneFont: '日本Miss eye dor睫毛嫁接',
		membersTwoFont: '日本mao gel美甲',
		membersThreeFont: '法爾曼全身緊緻精油按摩',
		membersFourFont: 'MTG頭皮健康管理',

		skinProblemFont: '問題肌修復',
		skinProblemOneFont: 'LDM-MED',
		skinProblemTwoFont: '路創麗Healite嗨光',
		skinProblemThreeFont: '科醫人M22 AOPT',

		faceAndBodyFont: '顏面和身體整形',

		brandListFont: '品&nbsp;&nbsp;牌&nbsp;&nbsp;列&nbsp;&nbsp;表',
		// brandFont01: '海藍之謎',
		// brandFont02: '菲歐曼',
		// brandFont03: '法爾曼',
		// brandFont04: '寶麗',
		// brandFont05: '曉姿',
		// brandFont06: '海菲秀',
		// brandFont07: '超皮秒',
		// brandFont08: '歐洲之星',
		// brandFont09: '超聲刀',
		// brandFont10: '美修斯',
		// brandFont11: '熱立塑',
		// brandFont12: '飛頓',
		// brandFont13: 'AGLEX',
		// brandFont14: '保妥適',
		// brandFont15: '喬雅登',
		// brandFont16: '瑞藍',
		// brandFont17: '熱瑪吉',
		// brandFont18: '美迪邁',
		brandFont01: 'LA MER',
		brandFont02: 'FILLMED',
		brandFont03: 'VALMONT',
		brandFont04: 'POLA',
		brandFont05: 'AXXZIA',
		brandFont06: 'hydrafacial',
		brandFont07: 'PicoWay',
		brandFont08: 'Fotona',
		brandFont09: 'ULTHERA',
		brandFont10: 'EMSCULPT',
		brandFont11: 'Liposonix',
		brandFont12: 'Alma',
		brandFont13: 'AGLEX',
		brandFont14: 'BOTOX',
		brandFont15: 'JUVEDERM',
		brandFont16: 'RESTYLANE',
		brandFont17: 'thermage',
		brandFont18: 'ENDYMED',
		brandFont19: 'LDM-MED',
		brandFont20: 'Lutronic',
		brandFont21: 'BTL',
		brandFont22: 'Lumenis',

		checkFont: '搜 索',
		placeFont: '請輸入關鍵字搜索',
		selectLangageFont: '選擇您所在的地區和語言',
		selectLangageFont1: '當前已選定',
		selectLangageFont2: '英國',
		selectLangageFont3: '柬埔寨',
		selectLangageFont4: '國際',

		selectLangFont1: '英語',
		selectLangFont2: '簡體中文',
		selectLangFont3: '繁體中文',
		selectLangFont4: '高棉語',
		companyAddr: '英國倫敦麥達維爾56號 W9 1PP',
		wechatFont: '微信',
		xiaohongshuFont: '小紅書',
		address: '地址',
		telephone: '座機號碼',
		phone: '手機號碼',
	},
};
