import Vue from 'vue';
import Vuex from 'vuex';
import Axiox from 'axios';
import { getCountryValue } from '@/utils/index';
Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		// baseUrl: 'http://192.168.0.164:8080/app/beauty',

		baseUrl: 'https://hk.wistechx.cn/app/api/beauty', // 线上
		langType: localStorage.getItem('DefaultLanguage') || 'en-US',
		curCountry: localStorage.getItem('currentCountry') || '',
		jumpPath: localStorage.getItem('jumpPath') || '',
	},
	mutations: {
		setCountry(state, value) {
			state.curCountry = value;
			localStorage.setItem('currentCountry', value);
		},
		delCountry(state) {
			state.curCountry = null;
			localStorage.removeItem('currentCountry');
		},
		setCountryList(state, value) {
			state.countryList = value;
		},
		setJumpPath(state, value) {
			state.jumpPath = value;
			localStorage.setItem('jumpPath', value);
		},
		delJumpPath(state) {
			state.jumpPath = null;
			localStorage.removeItem('jumpPath');
		},
	},
	actions: {
		async setAsyncCountry(payload) {
			let baseUrl = payload.state.baseUrl;
			const { data: ipRet } = await Axiox.get(baseUrl + '/countryGet');
			let country = ipRet.country; // 所属ip国家
			if (country == '内网IP') {
				country = '其他地区';
			}
			payload.commit('setCountry', country);
		},
	},
	modules: {},
});
