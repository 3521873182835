<template>
	<div>
		<!-- 柬埔寨 -->
		<div class="footer-page-contaienr" v-if="curCountry == '柬埔寨'">
			<div class="addr-main" style="width: 60%">
				<div class="img-div">
					<img src="../assets/imgs/footer/email.png" />
				</div>
				<p
					class="addr-details"
					style="cursor: pointer"
					:data-clipboard-text="emailNumber"
					@click="copyBankAccount"
				>
					contact@monetbeauty.com
				</p>
			</div>
			<div class="topStyle">
				<a
					href="https://www.xiaohongshu.com/user/profile/602b57750000000001005481"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src="../assets/imgs/footer/red.png" style="margin-right: 16px" />
				</a>
				<a
					href="https://facebook.com/moneatbeauty"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						src="../assets/imgs/footer/facebook.png"
						style="margin-right: 16px"
					/>
				</a>
				<a
					href="https://www.instagram.com/monetbeautyclinic/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						src="../assets/imgs/footer/instagram.png"
						style="margin-right: 16px"
					/>
				</a>
			</div>
		</div>

		<!-- 国际 -->
		<div class="footer-page-container1" v-else>
			<!-- pc -->
			<div class="bottom-pc-info">
				<div class="addr-main">
					<div
						class="copyright"
						style="
color: rgba(255,255,255,0.6);"
					>
						Monet beauty clinic London 2023 © All rights reserved.
					</div>
					<div class="email-wrap">
						<div class="img-div">
							<img src="../assets/imgs/footer/email.png" />
						</div>
						<p>
							<span
								class="addr-details"
								style="cursor: pointer;
color: rgba(255,255,255,0.6);;font-size: 15px;"
								:data-clipboard-text="emailNumber"
								@click="copyBankAccount"
								>info@monetbeauty.co.uk</span
							>
						</p>
					</div>
				</div>
				<div class="topStyle">
					<div class="whatsapp-icon-wrap">
						<img
							v-if="whatsappCodeShow"
							class="watchs-code"
							src="../assets/imgs/contact/pc_watchapp.png"
							alt=""
						/>
						<img
							src="../assets/imgs/contact/whatsapp.png"
							class="bottom-icon whatsapp-icon"
							style="margin-right: 16px"
							@click.stop="toggleQrcode('whatsapp')"
						/>
					</div>
					<div class="wechat-icon-wrap">
						<img
							v-if="wechatCodeShow"
							class="watchs-code"
							src="../assets/imgs/contact/pc_wechat.png"
							alt=""
						/>
						<img
							src="../assets/imgs/contact/wechat.png"
							style="margin-right: 16px"
							@click.stop="toggleQrcode('wechat')"
						/>
					</div>
					<a
						href="http://xhslink.com/DgzFnr"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							style="margin-right: 16px"
							src="../assets/imgs/contact/xiaohongshu.png"
						/>
					</a>
					<a
						href="https://www.instagram.com/monetbeautylondon?utm_source=qr"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="../assets/imgs/contact/instagram.png"
							style="margin-right: 16px"
						/>
					</a>
					<a
						href="https://facebook.com/moneatbeauty"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="../assets/imgs/contact/facebook.png"
							style="margin-right: 16px"
						/>
					</a>
				</div>
			</div>
			<!-- mobile -->
			<div class="mobile-bottom-footer">
				<div class="icon-info-card">
					<div class="wechat-icon-wrap">
						<img
							v-if="whatsappCodeShow"
							class="watchs-code"
							src="../assets/imgs/contact/pc_watchapp.png"
							alt=""
						/>
						<img
							style="width: 40px; height: 41px;"
							src="../assets/imgs/contact/mobile_whats_icon.png"
							@click.stop="toggleQrcode('whatsapp')"
						/>
					</div>
					<div class="wechat-icon-wrap">
						<img
							v-if="wechatCodeShow"
							class="watchs-code"
							src="../assets/imgs/contact/pc_wechat.png"
							alt=""
						/>
						<img
							style="width: 40px; height: 41px;"
							src="../assets/imgs/contact/mobile_wechat_icon.png"
							@click.stop="toggleQrcode('wechat')"
						/>
					</div>

					<a
						href="http://xhslink.com/DgzFnr"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							style="width: 40px; height: 41px;"
							src="../assets/imgs/contact/mobile_xiaohongshu_icon.png"
						/>
					</a>
					<a
						href="https://www.instagram.com/monetbeautylondon?utm_source=qr"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							style="width: 40px; height: 41px;"
							src="../assets/imgs/contact/mobile_ins_icon.png"
						/>
					</a>
					<a
						href="https://facebook.com/moneatbeauty"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							style="width: 40px; height: 41px;"
							src="../assets/imgs/contact/mobile_facebook_icon.png"
						/>
					</a>
				</div>
				<div class="email-info-card">
					<div class="copyright">
						Monet beauty clinic London 2023 © All rights reserved.
					</div>
					<div class="bottom-info-row">
						<div class="email-wrap">
							<img
								src="../assets/imgs/footer/mobile_email.png"
								class="email-icon"
								alt=""
							/>
							<span
								class="email-font addr-details"
								:data-clipboard-text="emailNumber"
								@click="copyBankAccount"
							>
								info@monetbeauty.co.uk
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			langType: localStorage.getItem('DefaultLanguage'),
			emailNumber: 'contact@monetbeauty.com',
			wechatCodeShow: false,
			whatsappCodeShow: false,
		};
	},
	computed: {
		...mapState(['curCountry']),
	},
	mounted() {
		window.addEventListener('click', () => {
			if (this.whatsappCodeShow) {
				this.whatsappCodeShow = false;
			}
			if (this.wechatCodeShow) {
				this.wechatCodeShow = false;
			}
		});
	},
	methods: {
		// 复制邮箱
		copyBankAccount() {
			let clipboard = new Clipboard('.addr-details');
			this.langType = localStorage.getItem('DefaultLanguage');
			clipboard.on('success', (e) => {
				let msg = '';
				if (this.langType == 'zh-CN') {
					msg = '复制成功';
				} else if (this.langType == 'zh-TW') {
					msg = '複製成功';
				} else if (this.langType == 'en-US') {
					msg = 'Copy success';
				} else {
					msg = 'Copy success';
				}
				this.$message.success({
					message: msg,
					showClose: true,
					duration: 2000,
				});
				// 释放内存
				clipboard.destroy();
			});
			clipboard.on('error', (e) => {
				let msg2 = '';
				if (this.langType == 'zh-CN') {
					// 该浏览器不支持自动复制
					msg2 = '复制失败';
				} else if (this.langType == 'zh-TW') {
					msg2 = '複製失敗';
				} else if (this.langType == 'en-US') {
					msg2 = 'Copy the failure';
				} else {
					msg2 = 'Copy the failure';
				}
				// 不支持复制
				this.$message.error({
					message: msg2,
					showClose: true,
					duration: 2000,
				});
				// 释放内存
				clipboard.destroy();
			});
		},
		toggleQrcode(type) {
			if (type == 'whatsapp') {
				this.whatsappCodeShow = !this.whatsappCodeShow;
				if (this.whatsappCodeShow) {
					this.wechatCodeShow = false;
				}
			} else {
				this.wechatCodeShow = !this.wechatCodeShow;
				if (this.wechatCodeShow) {
					this.whatsappCodeShow = false;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
// 柬埔寨
@import url('./style/cambodia_footer.scss');

// 国际
@import url('./style/internation_footer.scss');

@media screen and (max-width: 767px) {
	.footer-page-contaienr {
		padding: 15px 15px 29px;
		text-align: left;
		box-sizing: border-box;

		.addr-main {
			width: 60%;
			display: flex;
		}

		.topStyle {
			display: flex;
		}

		.img-div {
			line-height: 0;
		}

		img {
			width: 20px;
			height: 20px;
		}

		.addr-details {
			margin-left: 10px;
			font-size: 13px !important;
		}
	}
}

@media screen and (max-width: 768px) {
	.bottom-pc-info {
		display: none;
	}
	.footer-page-container1 {
		width: 100%;
		text-align: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		.mobile-bottom-footer {
			width: 100%;
		}
		.topStyle,
		.addr-main {
			display: none;
		}
		.email-info-card {
			padding: 17px 0;
			box-sizing: border-box;
			.copyright {
				color: rgba(255, 255, 255, 0.8);
				font-size: 12px;
				padding: 0 30px;
				box-sizing: border-box;
				text-align: center;
			}
			.bottom-info-row {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 10px;
				box-sizing: border-box;
				margin-top: 8px;
				.email-wrap {
					.email-icon {
						width: 16px;
						height: 16px;
					}
					.email-font {
						font-size: 13px;
						font-family: Arial-Regular, Arial;
						font-weight: 400;
						color: rgba(255, 255, 255, 0.8);
						margin-left: 4px;
						letter-spacing: 0.3px;
					}
				}
				.bottom-right {
					margin-left: 10px;
					display: flex;
					align-items: center;
					.font {
						font-size: 12px;
						font-family: Arial-Regular, Arial;
						font-weight: 400;
						color: rgba(255, 255, 255, 0.8);
						margin-left: 10px;
					}
				}
			}
		}

		.font1 {
			font-size: 12px;
			font-family: Arial-Regular, Arial;
			font-weight: 400;
			color: rgba(255, 255, 255, 0.8);
		}

		.icon-info-card {
			width: 100%;
			display: flex;
			padding: 20px 66px 15px;
			box-sizing: border-box;
			justify-content: space-between;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);
			img {
				width: 40px;
				height: 41px;
				margin-right: 0;
			}
		}

		.whatsapp-icon-wrap {
			position: relative;
			.watchs-code {
				position: absolute;
				top: -155px;
				left: -52px;
				width: 141px;
				height: 151px;
			}
		}
		.wechat-icon-wrap {
			position: relative;
			.watchs-code {
				position: absolute;
				top: -155px;
				left: -52px;
				width: 141px;
				height: 151px;
			}
		}
	}
}
</style>
