// 获取英文名称
export const getCountryValue = (value) => {
	let res = 'English';
	switch (value) {
		case '英国':
			res = 'English';
			break;
		case '柬埔寨':
			res = 'Cambodia';
			break;
		case '其他地区':
			res = 'INTERNATIONAL';
			break;
	}
	return res;
};

export const getQueryString = (name) => {
	let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	let r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return decodeURIComponent(r[2]);
	}
	return null;
};

// 获取hash下url参数
export const getHashSearchParam = (key) => {
	const url = location.href;
	const hash = url.substring(url.indexOf('#') + 1);
	const searchIndex = hash.indexOf('?');
	const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : '';
	const usp = new URLSearchParams(search);
	return usp.get(key);
};

// 获取国家中文名称
export const getCountryChinese = (value) => {
	let res = '';
	switch (value) {
		case 'English':
			res = '英国';
			break;
		case 'Cambodia':
			res = '柬埔寨';
			break;
		case 'INTERNATIONAL':
			res = '其他地区';
			break;
	}
	return res;
};
