module.exports = {
	localization: {
		help01: 'Help every customer to achieve their beauty dreams safely.',
		help02: '',
		contactType: 'Contact Info',
		checkUsFont01: 'Find us by searching ',
		checkUsFont02: ' or ',
		checkUsFont03: '',
		homeFont: 'Home',
		aboutFont: 'About Us',
		treatmentFont: 'Treatments',
		changeFont: 'Share',
		newsFont: 'Media',
		contactFont: 'Contact',
		contactUs: 'Contact Us',
		bannerOne: 'Your beauty begins here',
		bannerOneEn: '',
		addrFont: 'Addr',
		addrDetailsFont:
			'Great Disciple Sar Bith St, Phnom Penh, Cambodia(near the back entrance of Aeon Mall)',
		addrDetailsLonDonFont: '56 Maida Vale London W9 1PP',
		addrDetailsHongKongFont:
			'Flat 7A,68 Kimberley Road,Tsim Sha Tsui Hong Kong',
		addrDetailsPHFont: 'Borey Peng Huoth Beoung Snor',

		telFont: 'Tel',
		emailFont: 'Email',
		copyrightFont: 'Copyright &copy Monet beauty clinic All Rights Reserved',
		brandStoryFont: 'About Us',
		brandStoryOneFont:
			'With the latest medical techniques and advanced equipment, Monet Beauty Clinic provides you professional, fashion and safest cosmetic treatments. “Help every client achieve their beauty dreams safely” is our founding mission!',
		brandStoryTwoFont:
			'Holding the philosophy of “natural, safety and beauty”, Monet Beauty Clinic is a chain cosmetic clinic that consists dozens specialized cosmetic centers: Plastic Surgery Center, Skin Care Center, Micro Plastic Surgery Center, Anti-aging Center, Health Management Center, etc.',
		brandStoryDetailsFont: 'Details',
		aboutUsFont: 'About Us',
		aboutUsConFont:
			'Monet Beauty Clinic is one of the largest medical aesthetic clinic in Europe offering a top-tier luxury skin treatment experience and utilising the latest FDA-approved facial, body, and skin technologies. Rooted in dermatological expertise and research, the headquarters has been based in Hong Kong since 2010. Monet Beauty Clinic is committed to providing the highest quality services, boasting decades of clinical experience, with a team of doctors hailing from star-studded backgrounds including China, Japan, South Korea, and the UK. We specialise in catering to Asian facial contours and creating an Asian aesthetic style, frequently participating in Asian medical aesthetic conferences for technical exchange.',
		aboutUsConSecondFont:
			'Monet Beauty Clinic integrates the most advanced non-surgical treatment methods from the United States, Europe, South Korea, and Japan. Our goal is to provide you with personalised treatment plans tailored to your specific needs. We excel in skin management, offering services including skin brightening, anti-aging, and skin rejuvenation. Additionally, we have achieved remarkable results in treatments such as fat reduction, facial injections, thread lifting, and hair treatments.',
		aboutUsConThirdFont:
			'To date, Monet Beauty Clinic has served over 30,000 clients from all over the world, earning a solid reputation while delivering luxurious services to its clients.',
		raiseHairFeatureFont:
			'Feature：A cosmetic is a substance or product used to enhance or alter the appearance of the face or the scent and texture of the body.Many cosmetics are designed to be applied to the face, hair and body...',
		moreFont: 'MORE',
		brandShopFont: 'Brand Stories',
		fourthTitleFont: 'Team Of Experts',
		fourthTitleConFont:
			'Convergent industry model  Cast beautiful butterfly change',
		consultingFont: 'Consulting',
		appointmentFont: 'Appointment',
		fifthTitleFont: 'Clinic Address',
		shareFont: 'Metamorphosis Sharing',
		newsInfoFont: 'Medical Beauty Information',
		timeFont: 'Update Time: ',

		jinBianFont: 'Phnom Penh Branch',
		lonDonFont: 'London Branch',
		jinBianPHFont: 'Phnom Penh PH Branch',
		hongKongFont: 'Hong Kong Branch',

		consultFont: 'Consultation',
		nameFont: 'Name',
		telephoneFont: 'Tel',
		consultContainerFont: 'Context',
		nameSureFont: 'The name cannot be empty',
		phoneSureFont: 'The phone cannot be empty',
		contentSureFont: 'The content cannot be empty',
		catalogFont: 'Catalog',
		langFont: 'Language',

		specialProjectFont: 'Our Treatments',
		// Rejuvenation & Thread Lift
		fightDeclineAscensionFont: 'Anti-aging & Facelift',
		fightDeclineAscensionFont01: 'Anti-aging & Facelift',
		fightOneFont: 'Fotona 4D Pro Laser',
		fightTwoFont: 'Ultherapy SMAS-Facelift',
		fightTenFont: 'ENDYMED 3Deep radiofrequency tightening & lift',
		fightThreeFont: 'Variety American Threadlift',
		fightFourFont: 'AXXZIA Stem Cell Needle Free Mesotherapy',
		fightFiveFont: 'Germany SEYO Non-invasive Robot ',
		fightSixFont: 'FILLMED Facial Anti-ageing Care',
		fightSevenFont: 'LA MER Facial Restoration & Lift',
		fightEightFont: 'LA MER Anti-aging Care',
		fightNineFont: 'Thermage FLX Skin Tightening',
		fightElevenFont: 'BTL Ultra360', // ff

		whiteningBeautyCreamFont: 'Whitening & Repairing Pigmentation',
		whiteningBeautyCreamFont01: 'Whitening & Repairing Pigmentation',
		whiteningOneFont: 'Picoway Facial Freckle Removal',
		whiteningTwoFont: 'Picoway Body Whitening',
		whiteningThreeFont: 'Japan AGLEX Phototherapy', // 日本AGLEX嫩肤美白舱
		whiteningFourFont: 'POLA BA Whitening',
		whiteningFiveFont: 'VALMONT Facial Whitening & Care',
		whiteningSixFont: 'Fillmed Facial Whitening & Care', // 菲欧曼面部皙白焕肤护理
		whiteningSevenFont: 'Japan High Dos VC Whitening Injection',

		cleanTheFillingWaterFont: 'Cleansing & Hydrating',
		cleanTheFillingWaterFont01: 'Cleansing & Hydrating',
		cleanOneFont: 'Hydrafacial Cleansing & Moisturizing',
		cleanTwoFont: 'LA MER Facial Cleansing & Moisturizing',
		cleanThreeFont: 'VALMONT Facial Moisturizing & Care',
		cleanFiveFont: 'FILLMED Facial Moisturizing & Care',

		shrinkPoresFont: 'Pore Refining',
		shrinkPoresFont01: 'Pore Refining',
		shrinkOneFont: 'Picoway Laser Pore Refining',
		shrinkTwoFont: 'Israel ENDYMED RF Microneedling',

		raiseHairFont: 'Hair Care',
		raiseHairFont01: 'Hair Care',
		hairOneFont: 'Fotona Laser Hair Restoration Treatment',
		hairTwoFont: 'Carbonated Spring Hair SPA',
		hairThreeFont: 'Kérastase Caviar Hair Treatment',
		hairFourFont: 'Revitacare Haircare',

		managementFont: 'Body Contouring',
		managementFont01: 'Body Contouring',
		managementOneFont: 'EMSCULPT Body Sculpting Treatment',
		managementTwoFont: 'Liposonix Body Sculpting Treatment', // Liposonix 热立塑溶脂塑形
		managementThreeFont: 'France Laser Fat Removal',

		privateRaiseFont: 'Intimate Care',
		privateRaiseFont01: 'Intimate Care',
		privateOneFont: 'Fotona Smooth Privacy Robot',
		privateTwoFont: 'Picoway Vaginal Beautification',
		privateFourFont: 'Uterus Warm Therapy',

		injectionFont: 'Injectables',
		injectionFont01: 'Injectables',
		injectionOneFont: 'Botox Anti-wrinkle Injection & Slimming',
		injectionTwoFont: 'Juvederm Filler',
		injectionThreeFont: 'Restylane Filler',
		injectionFourFont: 'Holland Ellanse Filler',
		injectionFiveFont: 'German Ella Nose Thread Lift',

		otherFont: 'Other Treatment',
		otherFont01: 'Other Treatment',
		otherOneFont: 'Alma Freeze Point Laser Hair Removal',
		otherTwoFont: 'Picoway Tattoo Removal',
		otherThreeFont: 'Picoway Dark Circle Treatment',
		otherFourFont: 'Hearty Injection',
		otherFiveFont: 'FILLMED Desensitization Treatment',
		otherSixFont: 'Japan/Korea Semi-Permanent Makeup',
		otherSevenFont: 'Japan Liver Detox & Immune Boosting Infusion',
		otherEightFont: 'LA MER Anti-aging Eye Care',

		membersOnlyFont: 'Member Exclusive',
		membersFont: 'Open to members only',
		membersOneFont: 'Japanese Miss eye dor eyelash grafting',
		membersTwoFont: 'Japanese mao gel Manicure',
		membersThreeFont: 'VALMONT body oil massage',
		membersFourFont: 'MTG Scalp Health Management',

		skinProblemFont: 'Skin Problem Repair',
		skinProblemOneFont: 'LDM-MED',
		skinProblemTwoFont: 'LUTRONIC Healite IIC',
		skinProblemThreeFont: 'Lumenis M22 AOPT ',

		faceAndBodyFont: 'Face And Body Reshaping',

		brandListFont: 'Brand List',
		brandFont01: 'LA MER',
		brandFont02: 'FILLMED',
		brandFont03: 'VALMONT',
		brandFont04: 'POLA',
		brandFont05: 'AXXZIA',
		brandFont06: 'hydrafacial',
		brandFont07: 'PicoWay',
		brandFont08: 'Fotona',
		brandFont09: 'ULTHERA',
		brandFont10: 'EMSCULPT',
		brandFont11: 'Liposonix',
		brandFont12: 'Alma',
		brandFont13: 'AGLEX',
		brandFont14: 'BOTOX',
		brandFont15: 'JUVEDERM',
		brandFont16: 'RESTYLANE',
		brandFont17: 'thermage',
		brandFont18: 'ENDYMED',
		brandFont19: 'LDM-MED',
		brandFont20: 'Lutronic',
		brandFont21: 'BTL',
		brandFont22: 'Lumenis',

		checkFont: 'Search',
		placeFont: 'Please enter keyword search',
		selectLangageFont: 'Choose your region and language',
		selectLangageFont1: 'Currently selected',
		selectLangageFont2: 'United Kingdom',
		selectLangageFont3: 'Cambodia',
		selectLangageFont4: 'INTERNATIONAL',

		selectLangFont1: 'English',
		selectLangFont2: 'Simplified Chinese',
		selectLangFont3: 'traditional Chinese',
		selectLangFont4: 'Khmer',
		companyAddr: '56 Maida Vale, London, UK W9 1PP',
		wechatFont: 'Wechat',
		xiaohongshuFont: 'Xiaohongshu',
		address: 'Address',
		telephone: 'Phone Number',
		phone: 'Telephone Number',
	},
};
