var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-header"},[_c('div',{staticClass:"header-page-container",attrs:{"id":"header-page"}},[_c('div',{staticClass:"top-computer"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-page-top-img"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"img-responsives",attrs:{"src":require("../assets/imgs/header/logo_main.png")}})])],1),_c('div',{staticStyle:{"display":"flex"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.toggleClick}},[_c('span',{staticClass:"navbar-toggler-icon"},[_c('img',{staticStyle:{"width":"30px","height":"20px"},attrs:{"src":_vm.lineImg2}})])]),_c('div',{staticClass:"header-page-top-nav"},[_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'blank-active': _vm.routeName === 'Home' }},[_c('router-link',{class:_vm.routeName === 'Contact' ||
													_vm.routeName === 'Details' ||
													_vm.routeName === 'List'
														? 'nav-contact'
														: _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home' ||
														  _vm.routeName === 'Service'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/","exact":""}},[_vm._v(" "+_vm._s(_vm.$t('localization.homeFont'))+" ")])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ active: _vm.routeName === 'About' }},[_c('router-link',{class:_vm.routeName === 'Contact' ||
													_vm.routeName === 'Details' ||
													_vm.routeName === 'List'
														? 'nav-contact'
														: _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home' ||
														  _vm.routeName === 'Service'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":{
													path: '/about',
													query: { country: _vm._getCountryValue(_vm.curCountry) },
												}}},[_vm._v(" "+_vm._s(_vm.$t('localization.aboutFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'blank-active': _vm.routeName === 'Service' }},[_c('router-link',{class:_vm.routeName === 'Contact' ||
													_vm.routeName === 'Details' ||
													_vm.routeName === 'List'
														? 'nav-contact'
														: _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home' ||
														  _vm.routeName === 'Service'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":{
													path: '/service',
													query: { country: _vm._getCountryValue(_vm.curCountry) },
												}}},[_vm._v(" "+_vm._s(_vm.$t('localization.treatmentFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ active: _vm.routeName === 'News' }},[_c('router-link',{class:_vm.routeName === 'Contact' ||
													_vm.routeName === 'Details' ||
													_vm.routeName === 'List'
														? 'nav-contact'
														: _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home' ||
														  _vm.routeName === 'Service'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":{
													path: '/news',
													query: { country: _vm._getCountryValue(_vm.curCountry) },
												}}},[_vm._v(" "+_vm._s(_vm.$t('localization.newsFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'contact-active': _vm.routeName === 'Contact' }},[_c('router-link',{class:_vm.routeName === 'Contact' ||
													_vm.routeName === 'Details' ||
													_vm.routeName === 'List'
														? 'nav-contact'
														: _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home' ||
														  _vm.routeName === 'Service'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":{
													path: '/contact',
													query: { country: _vm._getCountryValue(_vm.curCountry) },
												}}},[_vm._v(" "+_vm._s(_vm.$t('localization.contactFont')))])],1)])])])]),_c('div',{staticClass:"nav-margin language-style",on:{"click":_vm.chooseLangage}},[(
									(_vm.routeName === 'Service' && _vm.curCountry == '其他地区') ||
										(_vm.routeName === 'Share' && _vm.curCountry == '其他地区') ||
										(_vm.routeName === 'Home' && _vm.curCountry == '其他地区')
								)?_c('img',{staticClass:"lang-font-icon",attrs:{"src":require("../assets/imgs/header/lang_black.png")}}):_vm._e(),(
									(_vm.routeName == 'About' && _vm.curCountry == '其他地区') ||
										(_vm.routeName == 'News' && _vm.curCountry == '其他地区')
								)?_c('img',{staticClass:"lang-font-icon",attrs:{"src":require("../assets/imgs/header/lang_white.png")}}):_vm._e(),(
									(_vm.routeName == 'Contact' && _vm.curCountry == '其他地区') ||
										(_vm.routeName == 'List' && _vm.curCountry == '其他地区') ||
										(_vm.routeName === 'Details' && _vm.curCountry == '其他地区')
								)?_c('img',{staticClass:"lang-font-icon",attrs:{"src":require("../assets/imgs/header/lang_purple.png")}}):_vm._e(),(_vm.curCountry == '英国')?_c('img',{staticClass:"lang-font-icon",staticStyle:{"width":"22px","height":"15px","margin-right":"4px"},attrs:{"src":require("../assets/imgs/home/en.png")}}):_vm._e(),(_vm.curCountry == '柬埔寨')?_c('img',{staticClass:"lang-font-icon",staticStyle:{"width":"22px","height":"15px","margin-right":"4px"},attrs:{"src":require("../assets/imgs/home/jian.png")}}):_vm._e(),(
									_vm.routeName === 'Service' ||
										_vm.routeName === 'Share' ||
										_vm.routeName === 'Home'
								)?_c('span',{staticClass:"lang-font lang-black"},[_vm._v(_vm._s(_vm.langageValue(_vm.selectLang)))]):_vm._e(),(_vm.routeName == 'About' || _vm.routeName == 'News')?_c('span',{staticClass:"lang-font lang-white"},[_vm._v(_vm._s(_vm.langageValue(_vm.selectLang)))]):_vm._e(),(
									_vm.routeName == 'Contact' ||
										_vm.routeName == 'List' ||
										_vm.routeName === 'Details'
								)?_c('span',{staticClass:"lang-font lang-purple"},[_vm._v(_vm._s(_vm.langageValue(_vm.selectLang)))]):_vm._e()])])])]),_c('div',{staticClass:"top-phone"},[_c('div',{staticClass:"header-container"},[_c('div',{staticStyle:{"width":"25px","display":"flex"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light"},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.toggleClick}},[_c('span',{staticClass:"navbar-toggler-icon"},[_c('img',{staticStyle:{"width":"20px","height":"16px"},attrs:{"src":_vm.lineImg2}})])]),_c('div',{staticClass:"header-page-top-nav"},[_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'blank-active': _vm.routeName === 'Home' }},[_c('router-link',{class:_vm.routeName === 'Contact' || _vm.routeName === 'Details'
														? 'nav-contact'
														: _vm.routeName === 'Service' ||
														  _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/","exact":""}},[_vm._v(" "+_vm._s(_vm.$t('localization.homeFont'))+" ")])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ active: _vm.routeName === 'About' }},[_c('router-link',{class:_vm.routeName === 'Contact' || _vm.routeName === 'Details'
														? 'nav-contact'
														: _vm.routeName === 'Service' ||
														  _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/about"}},[_vm._v(" "+_vm._s(_vm.$t('localization.aboutFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'blank-active': _vm.routeName === 'Service' }},[_c('router-link',{class:_vm.routeName === 'Contact' || _vm.routeName === 'Details'
														? 'nav-contact'
														: _vm.routeName === 'Service' ||
														  _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/service"}},[_vm._v(" "+_vm._s(_vm.$t('localization.treatmentFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ active: _vm.routeName === 'News' }},[_c('router-link',{class:_vm.routeName === 'Contact' || _vm.routeName === 'Details'
														? 'nav-contact'
														: _vm.routeName === 'Service' ||
														  _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/news"}},[_vm._v(" "+_vm._s(_vm.$t('localization.newsFont')))])],1),_c('li',{staticClass:"nav-item nav-margin min-liHome",class:{ 'contact-active': _vm.routeName === 'Contact' }},[_c('router-link',{class:_vm.routeName === 'Contact' || _vm.routeName === 'Details'
														? 'nav-contact'
														: _vm.routeName === 'Service' ||
														  _vm.routeName === 'Share' ||
														  _vm.routeName === 'Home'
														? 'nav-blank'
														: 'nav-white',attrs:{"to":"/contact"}},[_vm._v(" "+_vm._s(_vm.$t('localization.contactFont')))])],1)])])])]),_c('div',{staticClass:"nav-margin language-style flex-center"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.switchLanguage}},[_c('div',{staticClass:"lang-icon"},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{staticStyle:{"width":"20px","height":"14px"},attrs:{"src":_vm.selectLang == 'zh-CN'
													? _vm.country1
													: _vm.selectLang == 'en-US'
													? _vm.country2
													: _vm.selectLang == 'zh-TW'
													? _vm.country3
													: _vm.country4}})])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.language),function(item){return _c('el-dropdown-item',{key:item.value,attrs:{"command":item.value}},[_c('a',{staticClass:"dropdown_a"},[_c('img',{staticStyle:{"width":"17px","height":"12px","margin-top":"-4px","margin-right":"3px"},attrs:{"src":item.img}}),_c('span',{class:{ selected: _vm.selectLang == item.value }},[_vm._v(_vm._s(item.label))])])])}),1)],1)],1)]),_vm._m(0),_c('div',{staticClass:"phone-lang",on:{"click":_vm.chooseMobileLangage}},[(_vm.curCountry == '英国')?_c('img',{staticClass:"lang-font-icon",staticStyle:{"width":"22px","height":"15px"},attrs:{"src":require("../assets/imgs/home/en.png")}}):_vm._e(),(_vm.curCountry == '柬埔寨')?_c('img',{staticClass:"lang-font-icon",staticStyle:{"width":"22px","height":"15px"},attrs:{"src":require("../assets/imgs/home/jian.png")}}):_vm._e(),(_vm.curCountry == '其他地区')?_c('img',{staticClass:"lang-font-icon",staticStyle:{"width":"24px","height":"24px"},attrs:{"src":require("../assets/imgs/header/mobile_lang_icon.png")}}):_vm._e()])])])]),_c('div',{staticClass:"phone-header-container"},[_c('div',{staticClass:"phone-header"},[_c('div',{staticClass:"phone-main"},[_c('ul',[_c('li',{staticClass:"phone-main-every",on:{"click":_vm.goTop}},[_c('router-link',{staticClass:"oldType",class:{ phoneClick: _vm.routeName === 'Home' },attrs:{"to":"/","exact":""}},[_c('img',{staticClass:"every-img",attrs:{"src":_vm.routeName === 'Home' ? _vm.indexImg : _vm.indexImg02}}),_c('span',[_vm._v(_vm._s(_vm.$t('localization.homeFont')))])])],1),_c('li',{staticClass:"phone-main-every",on:{"click":_vm.goTop}},[_c('router-link',{staticClass:"oldType",class:{ phoneClick: _vm.routeName === 'About' },attrs:{"to":{
									path: '/about',
									query: { country: _vm._getCountryValue(_vm.curCountry) },
								}}},[_c('img',{staticClass:"every-img",attrs:{"src":_vm.routeName === 'About' ? _vm.aboutImg : _vm.aboutImg02}}),_c('span',[_vm._v(_vm._s(_vm.$t('localization.aboutFont')))])])],1),_c('li',{staticClass:"phone-main-every",on:{"click":_vm.goTop}},[_c('router-link',{staticClass:"oldType",class:{ phoneClick: _vm.routeName === 'Service' },attrs:{"to":{
									path: '/service',
									query: { country: _vm._getCountryValue(_vm.curCountry) },
								}}},[_c('img',{staticClass:"every-img",attrs:{"src":_vm.routeName === 'Service' ? _vm.serviceImg : _vm.serviceImg02}}),_c('span',[_vm._v(_vm._s(_vm.$t('localization.treatmentFont')))])])],1),_c('li',{staticClass:"phone-main-every",on:{"click":_vm.goTop}},[_c('router-link',{staticClass:"oldType",class:{ phoneClick: _vm.routeName === 'News' },attrs:{"to":{
									path: '/news',
									query: { country: _vm._getCountryValue(_vm.curCountry) },
								}}},[_c('img',{staticClass:"every-img",attrs:{"src":_vm.routeName === 'News' ? _vm.newsImg : _vm.newsImg02}}),_c('span',[_vm._v(_vm._s(_vm.$t('localization.newsFont')))])])],1),_c('li',{staticClass:"phone-main-every",on:{"click":_vm.goTop}},[_c('router-link',{staticClass:"oldType",class:{ phoneClick: _vm.routeName === 'Contact' },attrs:{"to":{
									path: '/contact',
									query: { country: _vm._getCountryValue(_vm.curCountry) },
								}}},[_c('img',{staticClass:"every-img",attrs:{"src":_vm.routeName === 'Contact' ? _vm.contactImg : _vm.contactImg02}}),_c('span',[_vm._v(_vm._s(_vm.$t('localization.contactFont')))])])],1)])])]),_c('div',{staticClass:"hidden-div",on:{"click":_vm.closeClick}})]),_c('el-drawer',{attrs:{"visible":_vm.mobileDrawer,"direction":'ttb',"modal":false,"size":"100%;"},on:{"update:visible":function($event){_vm.mobileDrawer=$event}}},[_c('div',{staticClass:"langage-mobile-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('localization.selectLangageFont')))]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"list"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"col-tit"},[_vm._v(" United Kingdom ")]),_c('div',{staticClass:"col-sub"},[_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '英国' && _vm.selectLang == 'en-US',
								},on:{"click":function($event){return _vm.switchLanguage('en-US', '英国')}}},[_vm._v("English ")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '英国' && _vm.selectLang == 'zh-CN',
								},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '英国')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '英国' && _vm.selectLang == 'zh-TW',
								},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '英国')}}},[_vm._v("繁體中文")])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"col-tit"},[_vm._v(" Cambodia ")]),_c('div',{staticClass:"col-sub"},[_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'en-KH',
								},on:{"click":function($event){return _vm.switchLanguage('en-KH', '柬埔寨')}}},[_vm._v("កម្ពុជា។")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'en-US',
								},on:{"click":function($event){return _vm.switchLanguage('en-US', '柬埔寨')}}},[_vm._v("English")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'zh-CN',
								},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '柬埔寨')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'zh-TW',
								},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '柬埔寨')}}},[_vm._v("繁體中文")])])]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"col-tit"},[_vm._v(" INTERNATIONAL ")]),_c('div',{staticClass:"col-sub"},[_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '其他地区' && _vm.selectLang == 'en-US',
								},on:{"click":function($event){return _vm.switchLanguage('en-US', '其他地区')}}},[_vm._v("English")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '其他地区' && _vm.selectLang == 'zh-CN',
								},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '其他地区')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
									active: _vm.curCountry == '其他地区' && _vm.selectLang == 'zh-TW',
								},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '其他地区')}}},[_vm._v("繁體中文")])])])])])]),_c('el-drawer',{attrs:{"visible":_vm.drawer,"direction":'ttb',"modal":false,"size":"80%"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('div',{staticClass:"langage-pc-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('localization.selectLangageFont')))]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"list"},[_c('div',{staticClass:"column"},[_c('span',{staticClass:"col-tit"},[_vm._v("United Kingdom")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '英国' && _vm.selectLang == 'en-US',
							},on:{"click":function($event){return _vm.switchLanguage('en-US', '英国')}}},[_vm._v("English")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '英国' && _vm.selectLang == 'zh-CN',
							},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '英国')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '英国' && _vm.selectLang == 'zh-TW',
							},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '英国')}}},[_vm._v("繁體中文")])]),_c('div',{staticClass:"column"},[_c('span',{staticClass:"col-tit"},[_vm._v("Cambodia")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'en-KH',
							},on:{"click":function($event){return _vm.switchLanguage('en-KH', '柬埔寨')}}},[_vm._v("កម្ពុជា។")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'en-US',
							},on:{"click":function($event){return _vm.switchLanguage('en-US', '柬埔寨')}}},[_vm._v("English")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'zh-CN',
							},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '柬埔寨')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '柬埔寨' && _vm.selectLang == 'zh-TW',
							},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '柬埔寨')}}},[_vm._v("繁體中文")])]),_c('div',{staticClass:"column"},[_c('span',{staticClass:"col-tit"},[_vm._v("INTERNATIONAL")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '其他地区' && _vm.selectLang == 'en-US',
							},on:{"click":function($event){return _vm.switchLanguage('en-US', '其他地区')}}},[_vm._v("English")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '其他地区' && _vm.selectLang == 'zh-CN',
							},on:{"click":function($event){return _vm.switchLanguage('zh-CN', '其他地区')}}},[_vm._v("简体中文")]),_c('span',{staticClass:"col-sub-tit",class:{
								active: _vm.curCountry == '其他地区' && _vm.selectLang == 'zh-TW',
							},on:{"click":function($event){return _vm.switchLanguage('zh-TW', '其他地区')}}},[_vm._v("繁體中文")])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-icon",staticStyle:{"padding-top":"14px"}},[_c('img',{staticStyle:{"width":"120px","height":"29px"},attrs:{"src":require("../assets/imgs/home/logo_icon1.png"),"alt":""}})])}]

export { render, staticRenderFns }