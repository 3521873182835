<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>
<script>
export default {
	provide() {
		return {
			baseUrl: this.$store.state.baseUrl,
			reload: this.reload,
			countryList: [],
		};
	},
	data() {
		return {
			isRouterAlive: true,
		};
	},
	mounted() {},
	created() {
		let countryUrl = this.getQueryString('country');
		let chineseCountry = this._getCountryChinese(countryUrl);

		if (chineseCountry) {
			this.$store.commit('setCountry', chineseCountry);
			localStorage.setItem('currentCountry', chineseCountry);
		} else {
			this.$store.dispatch('setAsyncCountry');
			this.getCountryList();
		}
	},
	methods: {
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(function() {
				this.isRouterAlive = true;
			});
		},
		getCountryList() {
			let url = this.$store.state.baseUrl + '/realmNameList';
			this.$axios.get(url).then((res) => {
				if (res.data && res.data.length) {
					this.countryList = res.data;
					// 80柬埔寨
					// this.countryList[0]['realmName'] = `http://192.168.0.117:8080/`;
					// this.countryList[1]['realmName'] = `http://192.168.0.117:8081/`;
					// this.countryList[2]['realmName'] = `http://192.168.0.117:8080/`;
					this.getCountry();
				}
			});
		},
		// 通过ip跳转当前国家
		getCountry() {
			let url = this.$store.state.baseUrl + '/countryGet';
			this.$axios.get(url).then((res) => {
				// 直接进入,搜索用户ip位置
				let data = res.data;
				let country = data.country;
				if (country != '柬埔寨' && country != '英国') {
					country = '其他地区';
				}
				this.$store.commit('setCountry', country);
				localStorage.setItem('currentCountry', country);
				let findIndex = this.countryList.findIndex(
					(item) => item.country == country
				);
				if (findIndex > -1) {
					let address = this.countryList[findIndex]['realmName'];
					let countryValue = this._getCountryValue(country);
					let jumpPath = `${address}?lang=en-US&country=${countryValue}`;
					window.location.replace(jumpPath);
				}
			});
		},
		// 获取hash下url参数
		getHashSearchParam(key) {
			const url = location.href;
			const hash = url.substring(url.indexOf('#') + 1);
			const searchIndex = hash.indexOf('?');
			const search = searchIndex !== -1 ? hash.substring(searchIndex + 1) : '';
			const usp = new URLSearchParams(search);
			return usp.get(key);
		},
		// 获取url中某一个参数
		getQueryString(name) {
			let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
			let r = window.location.search.substr(1).match(reg);
			if (r != null) {
				return decodeURIComponent(r[2]);
			}
			return null;
		},
		// 获取英文名称
		_getCountryValue(value) {
			let res = 'English';
			switch (value) {
				case '英国':
					res = 'English';
					break;
				case '柬埔寨':
					res = 'Cambodia';
					break;
				case '其他地区':
					res = 'INTERNATIONAL';
					break;
			}
			return res;
		},
		// 获取国家中文名称
		_getCountryChinese(value) {
			let res = '';
			switch (value) {
				case 'English':
					res = '英国';
					break;
				case 'Cambodia':
					res = '柬埔寨';
					break;
				case 'INTERNATIONAL':
					res = '其他地区';
					break;
			}
			return res;
		},
	},
};
</script>
<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>
