module.exports = {
	localization: {
		help01: '帮助每一位爱美的朋友',
		help02: '安全放心的达成美丽心愿',
		contactType: '联&nbsp;&nbsp;系&nbsp;&nbsp;方&nbsp;&nbsp;式',
		checkUsFont01: '通过搜索',
		checkUsFont02: '或',
		checkUsFont03: '找到我们',
		homeFont: '首  页',
		aboutFont: '关于我们',
		treatmentFont: '服务项目',
		changeFont: '蜕变分享',
		newsFont: '医美资讯',
		contactFont: '联系我们',
		contactUs: '联系我们',
		bannerOne: '你的美，从这里开始',
		bannerOneEn: 'Your beauty begins here.',
		addrFont: '地址',
		addrDetailsFont:
			'金边市桑园区百色河分区12村联合办公大楼（靠近永旺商场后门）',
		addrDetailsLonDonFont: '英国伦敦麦达维尔56号 W9 1PP',
		addrDetailsHongKongFont: '香港，尖沙咀，金巴利街68号，7A',
		addrDetailsPHFont: '金边市铁桥头区炳发城',

		telFont: '电话',
		emailFont: '邮箱',
		copyrightFont: '柬埔寨美奈美颜诊所版权所有',
		brandStoryFont: '关&nbsp;&nbsp;于&nbsp;&nbsp;我&nbsp;&nbsp;们',
		brandStoryOneFont:
			'柬埔寨美奈美颜诊所秉承只要专业定制的技术及服务理念，医院的成立为更多爱美者提供了更专业、更时尚、更安全的医美服务。美奈美颜始终把“帮助每一位爱美的朋友安全放心的达成美丽心愿”作为医院发展的使命！',
		brandStoryTwoFont:
			'柬埔寨美奈美颜诊所是品质连锁整形美容医院，始终遵循“自然、安全、美观”的服务理念。专业开设整形美容中心、皮肤美容中心、微整形中心、抗衰老中心、健康管理等十余项临床科室和辅助科室',
		brandStoryDetailsFont: '查看详情',
		aboutUsFont: '关于我们',
		aboutUsConFont:
			'伦敦Monet Beauty Clinic所是欧洲最大的微整形医美诊所之一，拥有顶级奢华的护肤体验，使用最新的FDA面部，身体和皮肤技术。源于皮肤科的专业知识和研究，自2010年起总部设立在香港。Monet Beauty Clinic专注于最优质的服务，我们拥有数十年的临床经验，医生团队来自包括中国、日本、韩国和英国等明星医师。我们专业提供针对亚洲人面部轮廓，打造亚洲审美风格，经常参加亚洲医美大会并进行技术交流。',
		aboutUsConSecondFont:
			'美奈美颜结合了美国、欧洲、韩国和日本最先进的非手术治疗方法。我们的目标是为您提供定制化的治疗方案，量身定制您的需求。我们擅长皮肤管理，包括美白、抗衰老和皮肤年轻化等服务。此外，我们在减脂、面部注射、拔丝和植发等治疗方面也做出了非常出色的成绩。',
		aboutUsConThirdFont:
			'时至今日,美奈美颜已经服务了来自世界各地的30000多名客户，为客户提供奢华服务的同时,也获得了良好的声誉。',

		OurServicesFont: '服务项目',
		raiseHairFeatureFont:
			'项目特色：化妆品是一种物质或产品，用来增强或改变面部的外观或身体的香味和质感。许多化妆品是专为适用于面部、头发和身体而设计的...',
		moreFont: '查看更多',
		brandShopFont: '品&nbsp;&nbsp;牌&nbsp;&nbsp;门&nbsp;&nbsp;店',
		fourthTitleFont: '专 家 团 队',
		fourthTitleConFont: '汇聚行业楷模 铸就美丽蝶变',
		consultingFont: '咨询专家',
		appointmentFont: '立即预约',
		fifthTitleFont: '诊所地址',
		shareFont: '蜕变分享',
		newsInfoFont: '医美资讯',
		timeFont: '更新时间：',

		jinBianFont: '金边永旺诊所',
		lonDonFont: '伦敦诊所',
		jinBianPHFont: '金边PH诊所',
		hongKongFont: '香港诊所',

		consultFont: '咨 询',
		nameFont: '姓名',
		telephoneFont: '联系电话',
		consultContainerFont: '咨询内容',
		nameSureFont: '姓名不能为空',
		phoneSureFont: '联系电话不能为空',
		contentSureFont: '咨询内容不能为空',
		catalogFont: '目录',
		langFont: '语言',

		specialProjectFont: '特&nbsp;&nbsp;色&nbsp;&nbsp;项&nbsp;&nbsp;目',
		fightDeclineAscensionFont: '抗衰提升',
		fightDeclineAscensionFont01: '抗&nbsp;&nbsp;衰&nbsp;&nbsp;提&nbsp;&nbsp;升',
		fightOneFont: 'Fotona 4D Pro',
		fightTwoFont: 'Ultherapy超声刀面部SMAS深层提升',
		fightTenFont: 'ENDYMED 3Deep相控射频紧致提升',
		fightThreeFont: '美国多种线材线雕提升',
		fightFourFont: '日本晓姿干细胞无针水光护理',
		fightFiveFont: '德国SEYO无创头皮水光品相机器人',
		fightSixFont: '菲欧曼面部极致抗衰护理',
		fightSevenFont: '海蓝之谜面部修护提升护理',
		fightEightFont: '海蓝之谜鎏金焕颜抗衰护理',
		fightNineFont: '第五代热玛吉紧肤除皱',
		fightElevenFont: 'BTL Ultra360', // ff

		whiteningBeautyCreamFont: '美白祛斑',
		whiteningBeautyCreamFont01: '美&nbsp;&nbsp;白&nbsp;&nbsp;祛&nbsp;&nbsp;斑',
		whiteningOneFont: 'Picoway超皮秒面部祛斑',
		whiteningTwoFont: 'Picoway超皮秒身体美白',
		whiteningThreeFont: '日本AGLEX嫩肤美白舱',
		whiteningFourFont: 'POLA黑BA抗糖美白温感石膏',
		whiteningFiveFont: '法尔曼美白面部护理',
		whiteningSixFont: '菲欧曼面部皙白焕肤护理',
		whiteningSevenFont: '日本高浓度VC美白针',

		cleanTheFillingWaterFont: '清洁补水',
		cleanTheFillingWaterFont01: '清&nbsp;&nbsp;洁&nbsp;&nbsp;补&nbsp;&nbsp;水',
		cleanOneFont: '海菲秀深层清洁补水嫩肤',
		cleanTwoFont: '海蓝之谜面部净化补水护理',
		cleanThreeFont: '法尔曼面部水润补湿护理',
		cleanFiveFont: '菲欧曼面部水润保湿护理',

		shrinkPoresFont: '收缩毛孔',
		shrinkPoresFont01: '收&nbsp;&nbsp;缩&nbsp;&nbsp;毛&nbsp;&nbsp;孔',
		shrinkOneFont: 'Picoway超皮秒收缩毛孔',
		shrinkTwoFont: '以色列ENDYMED黄金微针',

		raiseHairFont: '生发养发',
		raiseHairFont01: '生&nbsp;&nbsp;发&nbsp;&nbsp;养&nbsp;&nbsp;发',
		hairOneFont: 'Fotona激光生发机器人',
		hairTwoFont: '日本碳酸泉头皮管理',
		hairThreeFont: '卡诗鱼子酱护发',
		hairFourFont: '丝丽生发动能素HAIRCARE',

		managementFont: '身材管理',
		managementFont01: '身&nbsp;&nbsp;材&nbsp;&nbsp;管&nbsp;&nbsp;理',
		managementOneFont: 'EMSCULPT美修斯增肌减脂',
		managementTwoFont: 'Liposonix 热立塑溶脂塑形',
		managementThreeFont: '法国巴黎时光溶脂刀',

		privateRaiseFont: '私密维养',
		privateRaiseFont01: '私&nbsp;&nbsp;密&nbsp;&nbsp;维&nbsp;&nbsp;养',
		privateOneFont: 'Fotona Smooth私密机器人',
		privateTwoFont: 'Picoway超皮秒私密漂红',
		privateFourFont: '日本暖宫护理仓',

		injectionFont: '注射塑形',
		injectionFont01: '注&nbsp;&nbsp;射&nbsp;&nbsp;塑&nbsp;&nbsp;形',
		injectionOneFont: '保妥适肉毒瘦脸瘦身去皱',
		injectionTwoFont: '乔雅登玻尿酸填充',
		injectionThreeFont: '瑞蓝玻尿酸填充',
		injectionFourFont: '荷兰Ellanse少女针',
		injectionFiveFont: '德国Ella线雕隆鼻',

		otherFont: '其他项目',
		otherFont01: '其&nbsp;&nbsp;他&nbsp;&nbsp;项&nbsp;&nbsp;目',
		otherOneFont: 'Alma冰点激光脱毛',
		otherTwoFont: 'PicoWay超皮秒洗纹身',
		otherThreeFont: 'Picoway超皮秒去黑眼圈',
		otherFourFont: '嗨体去颈纹',
		otherFiveFont: '菲欧曼面部舒敏修复护理',
		otherSixFont: '日韩半永久眉眼唇',
		otherSevenFont: '日本肝脏排毒增强抵抗力点滴',
		otherEightFont: '海蓝之谜抗衰眼部护理',

		membersOnlyFont: '会员专享',
		membersFont: '只针对会员开放',
		membersOneFont: '日本Miss eye dor睫毛嫁接',
		membersTwoFont: '日本mao gel美甲',
		membersThreeFont: '法尔曼全身紧致精油按摩',
		membersFourFont: 'MTG头皮健康管理',

		skinProblemFont: '问题肌修复',
		skinProblemOneFont: 'LDM-MED',
		skinProblemTwoFont: '路创丽Healite嗨光',
		skinProblemThreeFont: '科医人M22 AOPT',

		faceAndBodyFont: '颜面和身体整形',

		brandListFont: '品&nbsp;&nbsp;牌&nbsp;&nbsp;列&nbsp;&nbsp;表',
		brandFont01: 'LA MER',
		brandFont02: 'FILLMED',
		brandFont03: 'VALMONT',
		brandFont04: 'POLA',
		brandFont05: 'AXXZIA',
		brandFont06: 'hydrafacial',
		brandFont07: 'PicoWay',
		brandFont08: 'Fotona',
		brandFont09: 'ULTHERA',
		brandFont10: 'EMSCULPT',
		brandFont11: 'Liposonix',
		brandFont12: 'Alma',
		brandFont13: 'AGLEX',
		brandFont14: 'BOTOX',
		brandFont15: 'JUVEDERM',
		brandFont16: 'RESTYLANE',
		brandFont17: 'thermage',
		brandFont18: 'ENDYMED',
		brandFont19: 'LDM-MED',
		brandFont20: 'Lutronic',
		brandFont21: 'BTL',
		brandFont22: 'Lumenis',

		checkFont: '搜 索',
		placeFont: '请输入关键字搜索',
		selectLangageFont: '选择您所在的地区和语言',
		selectLangageFont1: '当前已选定',
		selectLangageFont2: '英国',
		selectLangageFont3: '柬埔寨',
		selectLangageFont4: '国际',

		selectLangFont1: '英语',
		selectLangFont2: '简体中文',
		selectLangFont3: '繁体中文',

		selectLangFont4: '高棉语',
		companyAddr: '英国伦敦麦达维尔56号 W9 1PP',
		wechatFont: '微信',
		xiaohongshuFont: '小红书',
		address: '地址',
		telephone: '座机号码',
		phone: '手机号码',
	},
};
